// import React, { useState, useEffect } from "react";
// import { useParams } from "react-router-dom";
// import RwaworldApi from "../api";
// import "../Stylesheets/ArticleCard.css"; 
// import SideMenu from "./SideMenu"; 

// const ArticleCard = () => {
//   const [article, setArticle] = useState(null);
//   const { pageId } = useParams();

//   useEffect(() => {
//     const fetchArticle = async () => {
//       try {
//         const articleData = await RwaworldApi.getArticleByPageID(pageId);
//         console.log(articleData);
//         setArticle(articleData);
//         console.log("These are the articles", articleData)

//       } catch (error) {
//         console.error("Error fetching article:", error);
//       }
//     };

//     fetchArticle();
//   }, [pageId]);

//   if (!article) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <div id="articlePage">
//           <div className="side-menu-container">
//  {/* **********SIDE MENU IS NOT ACTIVE UNTIL WORKING******** */}
//       {/* <SideMenu article={article} /> */}
//     </div>
//       <div className="article-card">
//         {article.nodes.map((node, index) => (
//           <div key={index} className="article-node">
//             {node.attributes && node.attributes.id && (
//               <p className="node-id">ID: {node.attributes.id}</p>
//             )}
//             {node.text && node.text.html && (
//               <div
//                 className="node-html"
//                 dangerouslySetInnerHTML={{ __html: node.text.html }}
//               />
//             )}
//             {node.type === 'image' && (
//               <ImageNode image={node.image} />
//             )}
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// const ImageNode = ({ image }) => {
//   const [imageUrl, setImageUrl] = useState(null);

//   useEffect(() => {
//     const fetchImage = async () => {
//       try {
//         const imageData = await RwaworldApi.fetchImageByAssetId(image.assetId);
//         setImageUrl(imageData.hostedUrl);
//       } catch (error) {
//         console.error("Error fetching image:", error);
//       }
//     };

//     fetchImage();
//   }, [image.assetId]);

//   if (!imageUrl) {
//     return <div>Loading image...</div>;
//   }

//   return <img src={imageUrl} alt={image.alt} />;
// };

// export default ArticleCard;

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import RwaworldApi from "../api";
import "../Stylesheets/ArticleCard.css"; 
import SideMenu from "./SideMenu"; 

const ArticleCard = () => {
  const [article, setArticle] = useState(null);
  const [metadata, setMetadata] = useState(null); // State to store article metadata
  const { pageId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch article data
        const articleData = await RwaworldApi.getArticleByPageID(pageId);
        setArticle(articleData);

        // Fetch article metadata
        const articleMetadata = await RwaworldApi.getArticleMetadataByPageID(pageId);
        setMetadata(articleMetadata);
        console.log(articleMetadata)
      } catch (error) {
        console.error("Error fetching article:", error);
      }
    };

    fetchData();
  }, [pageId]);

  if (!article || !metadata) {
    return <div>Loading...</div>;
  }

  // Update document head with metadata
  document.title = metadata.seo.title || article.title; // Use SEO title if available, otherwise use article title
  document.querySelector('meta[name="description"]').setAttribute("content", metadata.seo.description || ""); // Use SEO description if available

  return (
    <div id="articlePage">
      <div className="side-menu-container">
        {/* **********SIDE MENU IS NOT ACTIVE UNTIL WORKING******** */}
        {/* <SideMenu article={article} /> */}
      </div>
      <div className="article-card">
        {article.nodes.map((node, index) => (
          <div key={index} className="article-node">
            {node.attributes && node.attributes.id && (
              <p className="node-id">ID: {node.attributes.id}</p>
            )}
            {node.text && node.text.html && (
              <div
                className="node-html"
                dangerouslySetInnerHTML={{ __html: node.text.html }}
              />
            )}
            {node.type === 'image' && (
              <ImageNode image={node.image} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const ImageNode = ({ image }) => {
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const imageData = await RwaworldApi.fetchImageByAssetId(image.assetId);
        setImageUrl(imageData.hostedUrl);
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    };

    fetchImage();
  }, [image.assetId]);

  if (!imageUrl) {
    return <div>Loading image...</div>;
  }

  return <img src={imageUrl} alt={image.alt} />;
};

export default ArticleCard;
