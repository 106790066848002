import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "../Stylesheets/Taxonomy.css";
import RwaworldApi from "../api";

const Watches = () => {


    useEffect(() => {
        // Update meta tags for SEO
        document.title = "Tokenized Watches - Luxury Timepiece Tokenization";
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute(
            "content",
            "Discover tokenized ownership of luxury watches through user-friendly platforms like Watches.io, Kettle.fi, Openchrono, and ZwapX. Learn about the transformative shift in the luxury watch market and the benefits of timepiece tokenization."
          );
        }
        const metaKeywords = document.querySelector('meta[name="keywords"]');
        if (metaKeywords) {
          metaKeywords.setAttribute(
            "content",
            "tokenized watches, luxury watches, timepiece tokenization, NFTs, luxury watch market"
          );
        }
        const metaAuthor = document.querySelector('meta[name="author"]');
        if (metaAuthor) {
          metaAuthor.setAttribute("content", "RWA World");
        }

      }, []);

  return (
    <div>

      {/* Add remaining content here */}
      <div id="categorydiv">
        <h1 id="categoryname" style={{backgroundColor: "#f6ef6f"}}>Tokenized Watches</h1>
        <hr id="categorynamedivider"></hr>
        <p id="subcategorydescription">
          {" "}
          The luxury watch market, valued at <a id="link" href="https://www.bcg.com/publications/2023/luxury-watch-market-trends">$75 billion</a> according to research
          from Boston Consulting Group, is experiencing a transformative shift
          in the form of tokenization. With second-hand sales comprising 30% of
          the market, tokenization offers a new way for enthusiasts to own and
          trade these timeless pieces. Timepiece tokenization involves
          authenticating the watches, a process that entails the following:
        </p>
        <ul id="subcategorylist">
          <li>Checking for reports of theft or loss</li>
          <li>Photographing and documenting the watch</li>
          <li>Storing the watch securely in a custodian vault</li>
          <li>
            Minting a token on the blockchain to represent ownership of the
            underlying timepiece
          </li>
        </ul>
        <p id="subcategorydescription">
          Tokenized ownership of watches from prestigious brands such as Rolex,
          Patek Philippe, Omega, Audemars Piguet, Richard Mille, Breitling, and
          Tudor is now within reach through user-friendly marketplaces like 
          <a id="link" href="http://Watches.io"> watches.io</a>, <a id="link" href="http://Kettle.fi">Kettle.fi</a>, <a id="link" href="https://openchrono.io/en-gb">Openchrono</a>, and <a id="link" href="https://www.zwapx.com/">ZwapX</a>. These platforms enable
          users to purchase NFTs that represent ownership of the physical
          watches using cryptocurrencies, making the process of acquiring these
          luxury items more accessible than ever. The watches themselves are
          safely stored in secure vaults located in New York, London, or
          Switzerland, while the marketplaces typically maintain insurance
          policies covering the full fair market value of each watch, providing
          peace of mind for investors.
        </p>
        <p id="subcategorydescription">
          As of May 2024, these platforms have collectively processed
          approximately 800 transactions, including buy/sell and borrow/repay,
          with a total sales volume of around $7,000,000. This robust activity
          in the tokenized watch market underscores the growing interest in this
          innovative approach to luxury watch ownership. With more collectors
          and investors recognizing the benefits of tokenization, such as
          increased liquidity and fractional ownership, the tokenized watch
          market is poised for significant growth in the coming years, offering
          a promising outlook for potential investors.
        </p>
        {/* <Link to={`/taxonomy/${category}/${selectedCategory.links}`}>Check out Tokenized Watches</Link> */}

        {/* <ul id="subcategoriesul">
          {selectedCategory.subcategories.map((subcategory, index) => (
            <li id="subcategoriesitem" key={index}>
              {subcategory}
              <hr id="divider"></hr>
            </li>
          ))}
        </ul> */}
      </div>
    </div>
  );
};

export default Watches;
