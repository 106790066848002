import React, { useState, useEffect } from "react";
import "../Stylesheets/Homepage.css";
import card1 from "../Assets/card1.png";
import upc1image from "../Assets/upc1image.png";
import upc4image from "../Assets/upc4image.png";
import checkeredbg from "../Assets/checkeredbg.png";
import categories from "../Assets/categories.png";
import HeroSection from "./HomepageHero";
import Lottie from "lottie-react";
import PartnershipsAndUsers from "./homepagepartnerships";
import NewsletterPopup from "../Newsletter/NewsletterPopUp";
import main1 from "../Animations/main (1).json"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import landing from "../Animations/landing.json";

const Homepage = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [hasShownExitPopup, setHasShownExitPopup] = useState(false); // New state for exit popup
  
  
  const handlePopupToggle = () => {
    setShowPopup(!showPopup);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

    // Use effect to handle the exit popup
    // useEffect(() => {
    //   const handleBeforeUnload = (event) => {
    //     if (!hasShownExitPopup) {
    //       event.preventDefault();
    //       event.returnValue = '';
    //       setShowPopup(true);
    //       setHasShownExitPopup(true);
    //     }
    //   };
  
    //   const handleMouseLeave = (event) => {
    //     if (event.clientY < 0 && !hasShownExitPopup) {
    //       setShowPopup(true);
    //       setHasShownExitPopup(true);
    //     }
    //   };
  
    //   window.addEventListener('beforeunload', handleBeforeUnload);
    //   document.addEventListener('mouseleave', handleMouseLeave);
  
    //   return () => {
    //     window.removeEventListener('beforeunload', handleBeforeUnload);
    //     document.removeEventListener('mouseleave', handleMouseLeave);
    //   };
    // }, [hasShownExitPopup]);

      // Use effect to handle the exit popup
  useEffect(() => {
    const handleMouseLeave = (event) => {
      if (event.clientY < 0 && !hasShownExitPopup) {
        setShowPopup(true);
        setHasShownExitPopup(true);
      }
    };

    document.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      document.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, [hasShownExitPopup]);
  
  

  const upcBoxes = [
    {
      id: 1,
      title: "Dive Deep into Tokenized Assets",
      image: upc1image,
      count: "400+",
    },
    {
      id: 2,
      title: "Institutional Grade Research For The RWA Investor",
      image: card1,
      count: "5",
    },
    {
      id: 3,
      title: "RWA Categories:",
      image: categories,
      count: "13",
    },
    {
      id: 4,
      title: "Easily Compare Asset Issuers",
      image: upc4image,
      count: "",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 5000,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 7000,
    slidesToScroll: 1,
  };


return (
  <>
  <div id="rwabanner">RWA Day @ Permissionless III <a href="https://rwa.day" target="_blank"><button id="joinusbutton">JOIN US</button></a></div>
    <div className="container">
      {/* Left column */}
      <div className="column">
      <div className="hidden md:flex md:flex-col md:gap-4">
        {/* {upcBoxes.slice(0, 2).map((box) => (
          <div id="upcboxesupdated" className="upc-box" key={box.id}>
            <img id="upcimage1" className="upc-image" src={box.image} alt={box.title} />
            <h2 id="upcboxheader" className="upc-title">{box.title}</h2>
            <p id="upc-countID" className="upc-count">{box.count}</p>
          </div>
        ))} */}
                <div className="upc-box" id="left-top-upc">
          <img className="upc-image" id="upcimage1" src={upc1image} alt="Card 1" />
          <h2 className="upc-title" id="upcboxheader">RWA Projects Tracked:</h2>
          <p id="upc-countID" className="upc-count">400+</p>
        </div>
        <div className="upc-box" id="left-bottom-upc">
          <img className="upc-image" src={card1} alt="Card 1" />
          <h2 className="upc-title" id="upcboxheader">Institutional Grade Research</h2>
          <p  id="upc-countID" className="upc-count"></p>
        </div>
      </div>
      </div>


       <div className="main-content"  id="homepagemaincontent" >
         <div className="homepage-pic">
           <img src={checkeredbg} alt="checkeredbg" />
           <h1 id="homepagetitle">Real-World Asset <br></br> Intelligence</h1>
           <h3 id="homepagesubtitle">
             24/7 High Fidelity Tokenization Research
           </h3><button id="signupnewsletter" onClick={handlePopupToggle}>
             Sign Up For Our Newsletter
           </button>
       
           <div id="homepageanimationdiv">   
             <Lottie id="homepageanimation" animationData={main1} />
           </div>
           {showPopup && <NewsletterPopup onClose={handleClosePopup} />}
         </div>
       </div>
      
      {/* Right column */}
      <div className="column">

      <div  className="hidden md:flex md:flex-col md:gap-4">
        {upcBoxes.slice(2).map((box) => (
          <div id="upcboxesupdated" className="upc-box" key={box.id}>
            <img className="upc-image" src={box.image} alt={box.title} />
            <h2 id="upcboxheader" className="upc-title">{box.title}</h2>
            <p id="upc-countID" className="upc-count">{box.count}</p>
          </div>
        ))}
      </div>
    </div></div>

    {/* Render carousel only on mobile screens */}
    <div id="upcboxcarousel" className="md:hidden">
      <Slider {...settings}>
        {upcBoxes.map((box) => (
          <div id="carouselboxupc" key={box.id}>
            <div  className="upc-box">
              <img className="upc-image" src={box.image} alt={box.title} />
              <h2 className="upc-title">{box.title}</h2>
              <p className="upc-count">{box.count}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>

    <HeroSection />
    <PartnershipsAndUsers />
  </>
);
};

export default Homepage;

