import React from "react";
import { useNavigate } from "react-router-dom";
import "../Stylesheets/ProjectCard.css";
import nologo from "../Assets/nologo.png";
import {
  ChevronDownIcon,
  PhoneIcon,
  PlayCircleIcon,
  ChevronLeftIcon,
  ArrowTopRightOnSquareIcon,
} from "@heroicons/react/20/solid";

const ProjectCard = ({ project }) => {
  const navigate = useNavigate();
  // console.log(project)

  const {
    Name: name,
    URL: website,
    "Product Category": productCategory,
    Logo: logo,
    Summary: summary,
    "Issuer/Asset Category": issuerCategory,
    "Asset Sub-categories": assetSubCategory,

    // Add more fields as required
  } = project;

  const formatArray = (array) => {
    if (array && Array.isArray(array)) {
      return array.join(", ");
    }
    return "Not Available";
  };

  const handleClick = () => {
    navigate(`/projects/${name}`);
  };

  const categoryColors = [
    { category: "Equity and Stocks", color: "#f18f7a" },
    { category: "Bonds", color: "#DAF7A6" },
    { category: "- Asset Agnostic", color: "#a0f17a" },
    { category: "Real Estate", color: "#7af1cb" },
    { category: "Collectibles and Alternatives", color: "#f6ef6f" },
    { category: "Sustainable and Responsible Investments", color: "#6fb9f6" },
    { category: "Commodities and Precious Metals", color: "#6ff6ef" },
    { category: "Public Debt Instruments", color: "#de6ff6" },
    { category: "Financial Services and Payments", color: "#ca5cd6" },
    { category: "Insurance and Claims", color: "#c1b1c6" },
    { category: "Private Debt Instruments", color: "#99ff33" },
    { category: "Funds and Investment Vehicles", color: "#ff3300" },
    { category: "Physical infrastructure", color: "#00cc00" },
  ];

  const getCategoryColor = (category) => {
    const foundCategory = categoryColors.find(
      (cat) => cat.category === category
    );
    return foundCategory ? foundCategory.color : "white"; // Default color if category not found
  };

  const truncateSummary = (summary) => {
    if (summary.length > 300) {
      return summary.substring(0, 200) + "...";
    }
    return summary;
  };

  return (
    <div className="project-card">
      <div className="project-card-left">
        {/* <img src={logo} alt={`${name} Logo`} className="project-logo" /> */}
        <a  href={website} target='_blank'>
        <img
          src={logo || nologo}
          alt={`${name} Logo`}
          className="project-logo"
        /></a>
      </div>
      <div className="project-card-middle">
        <div className="project-info-top">
          <h3 id="cardName">{name}</h3>
          {/* <p id="cardCategory">{formatArray(issuerCategory)}</p> */}
          <p
            id="cardCategory"
            style={{
              backgroundColor: getCategoryColor(formatArray(issuerCategory)),
            }}
          >
            {formatArray(issuerCategory)}
          </p>
        </div>
        <p id="cardSummary">{truncateSummary(summary)}</p>
        {/* <button className="view-more-button" onClick={handleClick}>
          View More
        </button> */}
      </div>
      <div className="project-card-right">
        <div className="project-card-info">
          <p className="project-card-info-right">Asset Category:</p>
          <p id="categorybubble">{formatArray(assetSubCategory)}</p>
        </div>
        <div className="project-card-info">
          <p className="project-card-info-right">Product Category:</p>
          <p id="categorybubble">{formatArray(productCategory)}</p>
        </div>

        {/* <div className="project-card-info">
          <a id="projectCardWebsite" href={website} target="_blank">
            {" "}
            <ArrowTopRightOnSquareIcon className="h-5 w-5 flex-none text-gray-400 dark:text-white" />
            Visit Website
          </a>
        </div> */}
        <div className="project-card-info">
  <div className="view-more-button">
  <button id="viewmoreofthecard" className="view-more-button" onClick={handleClick}>
          View More
        </button>
  </div>
</div>


        {/* Add more project info here */}
      </div>
    </div>
  );
};

export default ProjectCard;

