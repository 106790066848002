// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import RwaworldApi from "../api";
// import "../Stylesheets/ResearchPage.css";
// import Newsletter from "../Newsletter/Newsletter";

// const Articles = () => {
//   const [articleData, setArticleData] = useState([]);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const cachedData = localStorage.getItem("articleData");
//         const cachedTimestamp = localStorage.getItem("articleDataTimestamp");
//         const currentTime = new Date().getTime();

//         if (
//           cachedData &&
//           cachedTimestamp &&
//           currentTime - parseInt(cachedTimestamp) < 7 * 24 * 60 * 60 * 1000
//         ) {
//           // If cached data exists and is less than 7 days old, use it
//           setArticleData(JSON.parse(cachedData));
//         } else {
//           // If no cached data exists or it's older than 7 days, fetch articles from the API
//           const articles = await RwaworldApi.getArticles();
//           // Cache the fetched articles in local storage along with the current timestamp
//           console.log("These are the articles", articles)

//           localStorage.setItem("articleData", JSON.stringify(articles));
//           localStorage.setItem("articleDataTimestamp", currentTime.toString());
//           setArticleData(articles);
//         }
//       } catch (error) {
//         console.error('Error fetching articles:', error);
//       }
//     };

//     fetchData();
//   }, []);

//   console.log(articleData)

//   const getArticleTitle = (article) => {
//     if (article.nodes && article.nodes.length > 0 && article.nodes[0].text) {
//       return article.nodes[0].text.text;
//     } else {
//       return "Untitled Article";
//     }
//   };

//   return (      
//     <div id="researchpagediv">      
//       <h1 id="researchpagetitle">Articles</h1>
//       <div id="researchMenu">
//         {articleData.map((article) => (
//           <div id="articlecardtitles" key={article.pageId}>
//             <Link to={`/research/${article.pageId}`}>
//               <h2 id="articletitle">{getArticleTitle(article)}</h2>
//               <hr></hr>
//             </Link>
//           </div>
//         ))}
//       </div>
//       {/* <Newsletter/> */}
//     </div>
//   );
// };

// export default Articles;


import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import RwaworldApi from "../api";
import "../Stylesheets/ResearchPage.css";
import Newsletter from "../Newsletter/Newsletter";

const Articles = () => {
  const [articleData, setArticleData] = useState([]);

  const articleImageUrls = {
    "651a2a1db569f9cf4fb356c5": "https://res.cloudinary.com/dqpftskf5/image/upload/v1713727531/rwa%20world/Research%20Page%20Images/history_zuzz7j.jpg",
    "6556e827c43b7008eaaec939": "https://res.cloudinary.com/dqpftskf5/image/upload/v1713727527/rwa%20world/Research%20Page%20Images/esg_iwpykn.jpg",
    "65dd453310c47e639154857e": "https://res.cloudinary.com/dqpftskf5/image/upload/v1713409409/rwa%20world/Research%20Page%20Images/On-Chain_Diversification_Cogito_s_Funds_Make_Tokenization_Exposure_Easier_than_Ever_hiekcz.webp",
    "6562b708cd86706205634082": "https://res.cloudinary.com/dqpftskf5/image/upload/v1713727522/rwa%20world/Research%20Page%20Images/collectibles_f6ekhn.jpg",
    "65a7262c47ade4118206713f": "https://res.cloudinary.com/dqpftskf5/image/upload/v1713409413/rwa%20world/Research%20Page%20Images/How_Chainlink_s_CCIP_Benefits_Tokenized_Real-World_Assets_jr6431.webp",
    "65b712432253653d394ff779": "https://res.cloudinary.com/dqpftskf5/image/upload/v1713409406/rwa%20world/Research%20Page%20Images/Understanding_Frax_s_V3_Model_and_the_Role_of_FXB_in_Decentralized_Stablecoins_awhehr.webp",
    "65c178d01d41d92efd85e52d": "https://res.cloudinary.com/dqpftskf5/image/upload/v1713409415/rwa%20world/Research%20Page%20Images/Fairer_Lending_with_Tokenization_How_Zivoe_is_Disrupting_Predatory_Loans_t3auvx.png",
    "65c41937472c5aa5237d2f97": "https://res.cloudinary.com/dqpftskf5/image/upload/v1713409407/rwa%20world/Research%20Page%20Images/Transforming_Real-World_Asset_Tokenization_An_Inside_Look_at_Plume_s_Modular_L2_Blockchain_Ecosystem_mnohs1.webp",
    "65cb716cd127a12b737a4973": "https://res.cloudinary.com/dqpftskf5/image/upload/v1713409411/rwa%20world/Research%20Page%20Images/Making_Mercata_-_Unlocking_the_Future_of_Tokenization_with_BlockApps_dclmko.png",
    "6535d1515190e82a7efb5e48": "https://res.cloudinary.com/dqpftskf5/image/upload/v1713727518/rwa%20world/Research%20Page%20Images/stablecoins_rvws5x.jpg",
    "65e3c714d486d28f681f54d9": "https://res.cloudinary.com/dqpftskf5/image/upload/v1713409417/rwa%20world/Research%20Page%20Images/Unlockd_Finance_Bringing_the_Keys_to_Tokenization_webyui.webp",
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const cachedData = localStorage.getItem("articleData");
        const cachedTimestamp = localStorage.getItem("articleDataTimestamp");
        const currentTime = new Date().getTime();

        if (
          cachedData &&
          cachedTimestamp &&
          currentTime - parseInt(cachedTimestamp) < 7 * 24 * 60 * 60 * 1000
        ) {
          // If cached data exists and is less than 7 days old, use it
          setArticleData(JSON.parse(cachedData));
        } else {
          // If no cached data exists or it's older than 7 days, fetch articles from the API
          const articles = await RwaworldApi.getArticles();
          // Cache the fetched articles in local storage along with the current timestamp
          console.log("These are the articles", articles)

          localStorage.setItem("articleData", JSON.stringify(articles));
          localStorage.setItem("articleDataTimestamp", currentTime.toString());
          setArticleData(articles);
        }
      } catch (error) {
        console.error('Error fetching articles:', error);
      }
    };

    fetchData();
  }, []);

  console.log(articleData)

  const getArticleTitle = (article) => {
    if (article.nodes && article.nodes.length > 0 && article.nodes[0].text) {
      return article.nodes[0].text.text;
    } else {
      return "Untitled Article";
    }
  };

  return (      
    <div id="researchpagediv">   
      {/* <h1 id="researchpagetitle">Articles</h1> */}
      <a id="researchpagetitle" href="/research/annualreport">RWA World Annual Report</a>
      <div id="researchMenu">
        {articleData.map((article) => (
          <div id="articlecardtitles" key={article.pageId}>
                {/* <hr id="hrs"></hr>    */}

            <Link to={`/research/${article.pageId}`}>
              <h2 id="articletitle">{getArticleTitle(article)}</h2>
              <img                   id="articleImage"  src={articleImageUrls[article.pageId]} alt="Article Image" />
              <hr></hr>
            </Link>
          </div>
        ))}
      </div>
      {/* <Newsletter/> */}
    </div>
  );
};

export default Articles;

