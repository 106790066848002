import React from 'react';
import "../Stylesheets/TabbedResearch.css";


const CanvaEmbed = () => {
  return (
    <div >  <a id='annualreportheader' href="https://www.canva.com/design/DAF3FCqWAR8/view?utm_content=DAF3FCqWAR8&amp;utm_campaign=designshare&amp;utm_medium=embeds&amp;utm_source=link"
        target="_blank" rel="noopener">RWA World Annual Report 2023</a>
      <div id='canvaembeddiv' style={{ position: 'relative', width: '40%', height: 1, paddingTop: '40%',
        paddingBottom: 0, boxShadow: '0 2px 8px 0 rgba(63,69,81,0.16)', marginTop: 0, marginLeft: 'auto', marginRight: 'auto',
        marginBottom: '0.9em', overflow: 'hidden', borderRadius: '8px', willChange: 'transform' }}>
            <h1></h1>
        <iframe loading="lazy" style={{ position: 'absolute', width: '100%', height: '100%', top: 0,
          left: 0, border: 'none', padding: 0, margin: 0, marginBottom: "-50%" }}
          src="https://www.canva.com/design/DAF3FCqWAR8/view?embed" allowFullScreen={true} allow="fullscreen">
        </iframe>
      </div>
    
    </div>
  );
}

export default CanvaEmbed;
