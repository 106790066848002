import React, { useState, useEffect } from 'react';
import { SunIcon, MoonIcon } from '@heroicons/react/20/solid'; // Import sun and moon icons

const DarkModeToggle = ({ onDarkModeToggle }) => {
  // Initialize dark mode state based on the value stored in local storage, default to false (light mode)
  const [isDarkMode, setIsDarkMode] = useState(localStorage.getItem('isDarkMode') === 'true');

  // Apply the dark mode class to the document element whenever the dark mode state changes
  useEffect(() => {
    console.log('Dark mode state:', isDarkMode); // Log the current dark mode state
    document.documentElement.classList.toggle('dark-mode', isDarkMode);
    // Store the current dark mode preference in local storage
    localStorage.setItem('isDarkMode', isDarkMode);
  }, [isDarkMode]);

  const toggleDarkMode = () => {
    // Toggle the state first
    setIsDarkMode(!isDarkMode);
    onDarkModeToggle(!isDarkMode);
  };

  return (
    <button onClick={toggleDarkMode} className={`flex items-center gap-5 ${isDarkMode ? 'text-white' : 'text-black'}`}>
      {isDarkMode ? <SunIcon className="h-5 w-5 text-yellow-500" /> : <MoonIcon className="h-5 w-5 text-gray-600" />}
      <span>{isDarkMode ? '' : ''}</span>
    </button>

  );
};

export default DarkModeToggle;


