// import React, { useState, useEffect, useMemo, lazy, Suspense } from "react";
// import { useNavigate } from "react-router-dom";
// // import ProjectCard from "./ProjectCard";
// import "../Stylesheets/ProjectGrid.css";
// import RwaworldApi from "../api";
// import headergrid from "../Assets/projectsdbheader.png";
// import "../Stylesheets/ProjectCard.css";
// // import SearchBar from "../SearchBar";
// import "../Stylesheets/SearchBar.css";
// // import ProjectFilter from "../CategoryFilter";
// import loadingGIF from "../Assets/loading.gif";
// import FilteredTags from "../FilteredSearch/FilterTags";

// const ProjectCard = lazy(() => import("./ProjectCard"));
// const SearchBar = lazy(() => import("../FilteredSearch/SearchBar"));
// const ProjectFilter = lazy(() => import("../FilteredSearch/CategoryFilter"));

// const ProjectGrid = () => {
//   const [originalProjects, setOriginalProjects] = useState([]);
//   const [displayedProjects, setDisplayedProjects] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [gridStyle, setGridStyle] = useState("single");
//   const [isFiltered, setIsFiltered] = useState(false);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [projectsPerPage] = useState(20);
//   const [selectedFilterTags, setSelectedFilterTags] = useState([]);
//   const [noResults, setNoResults] = useState(false);
//   const [selectedFilters, setSelectedFilters] = useState({
//     issuerAssetCategories: [],
//     productCategory: [],
//     blockchain: [],
//   });
//   const navigate = useNavigate();



//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const cachedData = localStorage.getItem("projectsData");
//         if (cachedData) {
//           const { projects: cachedProjects, lastFetchTime } =
//             JSON.parse(cachedData);
//           const currentTime = new Date().getTime();
//           const timeDifference = currentTime - lastFetchTime;
//           console.log("This is the cached data", cachedData);

//           // Check if cached data is still valid (e.g., less than 24 hours old)
//           if (timeDifference < 7 * 24 * 60 * 60 * 1000) {
//             setOriginalProjects(cachedProjects);
//             setDisplayedProjects(cachedProjects);
//             setLoading(false);
//             return;
//           }
//         }

//         // Fetch projects from Airtable
//         const response = await RwaworldApi.addAllProjectstoDB();
//         console.log("API Response:", response.projects);

//         // Store fetched projects in local storage
//         localStorage.setItem(
//           "projectsData",
//           JSON.stringify({
//             projects: response.projects,
//             lastFetchTime: new Date().getTime(), // Store current time as last fetch time
//           })
//         );

//         setOriginalProjects(response.projects || []);
//         setDisplayedProjects(response.projects || []);
//         setLoading(false);
//       } catch (error) {
//         console.error("Error fetching projects:", error);
//       }
//     };

//     fetchData();
//   }, []);

//   const sortedProjects = useMemo(() => {
//     return [...displayedProjects].sort((a, b) => a.Name.localeCompare(b.Name));
//   }, [displayedProjects]);

//   const currentProjects = useMemo(() => {
//     const indexOfLastProject = currentPage * projectsPerPage;
//     const indexOfFirstProject = indexOfLastProject - projectsPerPage;
//     return sortedProjects.slice(indexOfFirstProject, indexOfLastProject);
//   }, [currentPage, projectsPerPage, sortedProjects]);

//   const handlePageChange = (pageNumber) => {
//     setCurrentPage(pageNumber);
//   };

//   const handleGridButtonClick = () => {
//     setGridStyle(gridStyle === "single" ? "triple" : "single");
//   };
  
//   useEffect(() => {
//     console.log("Original projects length:", originalProjects.length);
//     console.log("Displayed projects length:", displayedProjects.length);

//     if (displayedProjects.length === 0 && originalProjects.length !== 0) {
//       console.log("Setting noResults to true...");
//       setNoResults(true);
//     } else {
//       console.log("Setting noResults to false...");
//       setNoResults(false);
//     }
//   }, [displayedProjects, originalProjects]);

//   const numDisplayedProjects = currentProjects.length;

//   const handleSearch = async (searchTerm) => {
//     setLoading(true);
//     try {
//       if (!searchTerm) {
//         setDisplayedProjects(originalProjects);
//         setIsFiltered(false);
//         setLoading(false);
//         return;
//       }
//       const response = await RwaworldApi.searchProjects(searchTerm);
//       if (
//         response &&
//         Array.isArray(response.projects) &&
//         response.projects.length > 0
//       ) {
//         setDisplayedProjects(response.projects);
//         if (response.projects.length === 1) {
//           navigate(`/projects/${response.projects[0].Name}`);
//         }
//       } else {
//         setDisplayedProjects([]);
//       }
//     } catch (error) {
//       console.error("Error searching projects:", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleFilterChange = (selectedFilters) => {
//     const filteredProjects = originalProjects.filter((project) => {
//       const matchesIssuerAssetCategory =
//         !selectedFilters.issuerAssetCategories.length ||
//         (project["Issuer/Asset Category"] &&
//           Array.isArray(project["Issuer/Asset Category"]) &&
//           project["Issuer/Asset Category"].some((cat) =>
//             selectedFilters.issuerAssetCategories.includes(cat)
//           ));

//       const matchesProductCategory =
//         !selectedFilters.productCategory.length ||
//         (project["Product Category"] &&
//           Array.isArray(project["Product Category"]) &&
//           project["Product Category"].some((cat) =>
//             selectedFilters.productCategory.includes(cat.trim())
//           ));

//       const matchesBlockchain =
//         !selectedFilters.blockchain.length ||
//         (project.Chain &&
//           Array.isArray(project.Chain) &&
//           project.Chain.some((chain) =>
//             selectedFilters.blockchain.includes(chain)
//           ));

//       // setDisplayedProjects(filteredProjects);
//       setSelectedFilterTags([]); // Clear previous selected filter tags
//       const tags = [];
//       for (const filterId in selectedFilters) {
//         selectedFilters[filterId].forEach((value) => {
//           tags.push(value);
//         });
//       }
//       setSelectedFilterTags(tags); // Update selected filter tags

//       return (
//         matchesIssuerAssetCategory &&
//         matchesProductCategory &&
//         matchesBlockchain
//       );
//     });

//     const tags = [];
//     for (const filterId in selectedFilters) {
//       selectedFilters[filterId].forEach((value) => {
//         tags.push(value);
//       });
//     }

//     setSelectedFilters(selectedFilters);
//     setSelectedFilterTags(tags);
//     setDisplayedProjects(filteredProjects);
//     setIsFiltered(true);
//   };

//   const handleCheckboxChange = (selectedFilters) => {
//     setSelectedFilterTags(selectedFilters);
//   };

//   const handleFilterTagRemove = (tagToRemove) => {
//     // Remove the tag from the selected filter tags
//     const updatedFilterTags = selectedFilterTags.filter(
//       (tag) => tag !== tagToRemove
//     );
//     setSelectedFilterTags(updatedFilterTags);

//     // If there are no remaining filter tags, display all projects
//     if (updatedFilterTags.length === 0) {
//       setDisplayedProjects(originalProjects);
//       setIsFiltered(false);
//       return;
//     }

//     // Filter the displayed projects based on the remaining filter tags
//     const filteredProjects = originalProjects.filter((project) => {
//       return updatedFilterTags.some((tag) => {
//         const matchesIssuerAssetCategory =
//           !tag ||
//           (project["Issuer/Asset Category"] &&
//             project["Issuer/Asset Category"].includes(tag));
//         const matchesProductCategory =
//           !tag ||
//           (project["Product Category"] &&
//             project["Product Category"].includes(tag));
//         const matchesBlockchain =
//           !tag || (project.Chain && project.Chain.includes(tag));

//         return (
//           matchesIssuerAssetCategory ||
//           matchesProductCategory ||
//           matchesBlockchain
//         );
//       });
//     });

//     setDisplayedProjects(filteredProjects);
//     setIsFiltered(true);
//   };

//   const handleProjectClick = (projectName) => {
//     navigate(`/projects/${projectName}`);
//   };

//   if (loading) {
//     return <img id="loadingscreen" src={loadingGIF} alt="Loading..." />;
//   }

  


//   return (
//     <div id="completeprojectgrid">
//       <div id="dbheaderdiv">
//         {" "}
//         <div id="dbheader">
//           {/* <h1 id="dbheadertitle">DATABASE</h1> */}
//           <h2 id="dbheadersentence">350+ Projects</h2>
//         </div>
//         <img id="dbheadergrid" src={headergrid} alt="Header Grid" />{" "}

//         <div id="filteredtagsdiv">
//           <FilteredTags
//             selectedFilters={selectedFilterTags}
//             onFilterTagRemove={handleFilterTagRemove}
//             tags={selectedFilterTags}
//             handleTagDelete={handleFilterTagRemove}
//           />
//         </div>
//       </div>
//         <div id="projectfilter">
//       <Suspense fallback={<div>Loading...</div>}>
//         <ProjectFilter
//           id="catfilter"
//           onFilterChange={handleFilterChange}
//           selectedFilters={selectedFilters}
//           numDisplayedProjects={numDisplayedProjects}
//           handleCheckboxChange={handleCheckboxChange}
//         />
//       </Suspense>
// </div>

//       <div className="project-grid-container">
//         <div className="project-grid">
//           {currentProjects.map((project) => (
//             <Suspense fallback={<div>Loading...</div>} key={project.Name}>
//               <ProjectCard
//                 project={project}
//                 onClick={() => handleProjectClick(project.Name)}
//               />
//             </Suspense>
//           ))}
//         </div>
//         {noResults && (
//           <div className="no-results-message">
//             Sorry, no projects match your filter criteria.
//           </div>
//         )}
//       </div>
//       <div id="paginationdiv">
//         <button
//           id="paginationkey"
//           disabled={currentPage === 1}
//           onClick={() => handlePageChange(currentPage - 1)}
//         >
//           Previous
//         </button>
//         {Array.from(
//           { length: Math.ceil(sortedProjects.length / projectsPerPage) },
//           (_, index) => {
//             if (index < 3) {
//               return (
//                 <button
//                   id="paginationkey"
//                   key={index}
//                   onClick={() => handlePageChange(index + 1)}
//                   className={currentPage === index + 1 ? "active" : ""}
//                 >
//                   {index + 1}
//                 </button>
//               );
//             }

//             if (
//               index >= currentPage - 2 &&
//               index <= currentPage + 2 &&
//               index < Math.ceil(sortedProjects.length / projectsPerPage) - 3
//             ) {
//               return (
//                 <button
//                   id="paginationkey"
//                   key={index}
//                   onClick={() => handlePageChange(index + 1)}
//                   className={currentPage === index + 1 ? "active" : ""}
//                 >
//                   {index + 1}
//                 </button>
//               );
//             }

//             if (
//               index ===
//                 Math.ceil(sortedProjects.length / projectsPerPage) - 4 &&
//               currentPage <
//                 Math.ceil(sortedProjects.length / projectsPerPage) - 3
//             ) {
//               return <span>...</span>;
//             }

//             if (
//               index ===
//               Math.ceil(sortedProjects.length / projectsPerPage) - 1
//             ) {
//               return (
//                 <button
//                   id="paginationkey"
//                   key={index}
//                   onClick={() => handlePageChange(index + 1)}
//                   className={currentPage === index + 1 ? "active" : ""}
//                 >
//                   {index + 1}
//                 </button>
//               );
//             }

//             return null;
//           }
//         )}
//         <button
//           id="paginationkey"
//           disabled={
//             currentPage === Math.ceil(sortedProjects.length / projectsPerPage)
//           }
//           onClick={() => handlePageChange(currentPage + 1)}
//         >
//           Next
//         </button>
//       </div>
//     </div>
//   );
// };

// export default ProjectGrid;


import React, { useState, useEffect, useMemo, lazy, Suspense } from "react";
import { useNavigate } from "react-router-dom";
// import ProjectCard from "./ProjectCard";
import "../Stylesheets/ProjectGrid.css";
import RwaworldApi from "../api";
import headergrid from "../Assets/projectsdbheader.png";
import "../Stylesheets/ProjectCard.css";
// import SearchBar from "../SearchBar";
import "../Stylesheets/SearchBar.css";
// import ProjectFilter from "../CategoryFilter";
import loadingGIF from "../Assets/loading.gif";
import FilteredTags from "../FilteredSearch/FilterTags";

const ProjectCard = lazy(() => import("./ProjectCard"));
const SearchBar = lazy(() => import("../FilteredSearch/SearchBar"));
const ProjectFilter = lazy(() => import("../FilteredSearch/CategoryFilter"));

const ProjectGrid = () => {
  const [originalProjects, setOriginalProjects] = useState([]);
  const [displayedProjects, setDisplayedProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [gridStyle, setGridStyle] = useState("single");
  const [isFiltered, setIsFiltered] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [projectsPerPage] = useState(20);
  const [selectedFilterTags, setSelectedFilterTags] = useState([]);
  const [noResults, setNoResults] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({
    issuerAssetCategories: [],
    productCategory: [],
    blockchain: [],
  });
  const navigate = useNavigate();



  useEffect(() => {
    const fetchData = async () => {
      try {
        const cachedData = localStorage.getItem("projectsData");
        if (cachedData) {
          const { projects: cachedProjects, lastFetchTime } =
            JSON.parse(cachedData);
          const currentTime = new Date().getTime();
          const timeDifference = currentTime - lastFetchTime;
          console.log("This is the cached data", cachedData);

          // Check if cached data is still valid (e.g., less than 24 hours old)
          if (timeDifference < 7 * 24 * 60 * 60 * 1000) {
            setOriginalProjects(cachedProjects);
            setDisplayedProjects(cachedProjects);
            setLoading(false);
            return;
          }
        }

        // Fetch projects from Airtable
        const response = await RwaworldApi.addAllProjectstoDB();
        console.log("API Response:", response.projects);

        // Store fetched projects in local storage
        localStorage.setItem(
          "projectsData",
          JSON.stringify({
            projects: response.projects,
            lastFetchTime: new Date().getTime(), // Store current time as last fetch time
          })
        );

        setOriginalProjects(response.projects || []);
        setDisplayedProjects(response.projects || []);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchData();
  }, []);

  const sortedProjects = useMemo(() => {
    return [...displayedProjects].sort((a, b) => a.Name.localeCompare(b.Name));
  }, [displayedProjects]);

  const currentProjects = useMemo(() => {
    const indexOfLastProject = currentPage * projectsPerPage;
    const indexOfFirstProject = indexOfLastProject - projectsPerPage;
    return sortedProjects.slice(indexOfFirstProject, indexOfLastProject);
  }, [currentPage, projectsPerPage, sortedProjects]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleGridButtonClick = () => {
    setGridStyle(gridStyle === "single" ? "triple" : "single");
  };
  
  useEffect(() => {
    console.log("Original projects length:", originalProjects.length);
    console.log("Displayed projects length:", displayedProjects.length);

    if (displayedProjects.length === 0 && originalProjects.length !== 0) {
      console.log("Setting noResults to true...");
      setNoResults(true);
    } else {
      console.log("Setting noResults to false...");
      setNoResults(false);
    }
  }, [displayedProjects, originalProjects]);

  const numDisplayedProjects = currentProjects.length;

  const handleSearch = async (searchTerm) => {
    setLoading(true);
    try {
      if (!searchTerm) {
        setDisplayedProjects(originalProjects);
        setIsFiltered(false);
        setLoading(false);
        return;
      }
      const response = await RwaworldApi.searchProjects(searchTerm);
      if (
        response &&
        Array.isArray(response.projects) &&
        response.projects.length > 0
      ) {
        setDisplayedProjects(response.projects);
        if (response.projects.length === 1) {
          navigate(`/projects/${response.projects[0].Name}`);
        }
      } else {
        setDisplayedProjects([]);
      }
    } catch (error) {
      console.error("Error searching projects:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = (selectedFilters) => {
    const filteredProjects = originalProjects.filter((project) => {
      const matchesIssuerAssetCategory =
        !selectedFilters.issuerAssetCategories.length ||
        (project["Issuer/Asset Category"] &&
          Array.isArray(project["Issuer/Asset Category"]) &&
          project["Issuer/Asset Category"].some((cat) =>
            selectedFilters.issuerAssetCategories.includes(cat)
          ));

      const matchesProductCategory =
        !selectedFilters.productCategory.length ||
        (project["Product Category"] &&
          Array.isArray(project["Product Category"]) &&
          project["Product Category"].some((cat) =>
            selectedFilters.productCategory.includes(cat.trim())
          ));

      const matchesBlockchain =
        !selectedFilters.blockchain.length ||
        (project.Chain &&
          Array.isArray(project.Chain) &&
          project.Chain.some((chain) =>
            selectedFilters.blockchain.includes(chain)
          ));

      // setDisplayedProjects(filteredProjects);
      setSelectedFilterTags([]); // Clear previous selected filter tags
      const tags = [];
      for (const filterId in selectedFilters) {
        selectedFilters[filterId].forEach((value) => {
          tags.push(value);
        });
      }
      setSelectedFilterTags(tags); // Update selected filter tags

      return (
        matchesIssuerAssetCategory &&
        matchesProductCategory &&
        matchesBlockchain
      );
    });

    const tags = [];
    for (const filterId in selectedFilters) {
      selectedFilters[filterId].forEach((value) => {
        tags.push(value);
      });
    }

    setSelectedFilters(selectedFilters);
    setSelectedFilterTags(tags);
    setDisplayedProjects(filteredProjects);
    setIsFiltered(true);
  };

  const handleCheckboxChange = (selectedFilters) => {
    setSelectedFilterTags(selectedFilters);
  };

  const handleFilterTagRemove = (tagToRemove) => {
    // Remove the tag from the selected filter tags
    const updatedFilterTags = selectedFilterTags.filter(
      (tag) => tag !== tagToRemove
    );
    setSelectedFilterTags(updatedFilterTags);

    // If there are no remaining filter tags, display all projects
    if (updatedFilterTags.length === 0) {
      setDisplayedProjects(originalProjects);
      setIsFiltered(false);
      return;
    }

    // Filter the displayed projects based on the remaining filter tags
    const filteredProjects = originalProjects.filter((project) => {
      return updatedFilterTags.some((tag) => {
        const matchesIssuerAssetCategory =
          !tag ||
          (project["Issuer/Asset Category"] &&
            project["Issuer/Asset Category"].includes(tag));
        const matchesProductCategory =
          !tag ||
          (project["Product Category"] &&
            project["Product Category"].includes(tag));
        const matchesBlockchain =
          !tag || (project.Chain && project.Chain.includes(tag));

        return (
          matchesIssuerAssetCategory ||
          matchesProductCategory ||
          matchesBlockchain
        );
      });
    });

    setDisplayedProjects(filteredProjects);
    setIsFiltered(true);
  };

  const handleProjectClick = (projectName) => {
    navigate(`/projects/${projectName}`);
  };

  if (loading) {
    return <div id="loadingscreencontainer"><img  id="loadingscreen" src={loadingGIF} alt="Loading..." /></div>;
  }

  const filterSectionStyle = {
    marginTop: numDisplayedProjects > 0 && numDisplayedProjects < 3 ? '-15%' : numDisplayedProjects >= 3 ? '-15%' : '-8%',
    marginBottom: numDisplayedProjects > 0 && numDisplayedProjects <= 3 ? '35%' : '0%',
    
    
    // Adjust other styles as needed
  };


  return (
    <div id="completeprojectgrid">
      <div id="dbheaderdiv">
        {" "}
        <div id="dbheader">
          {/* <h1 id="dbheadertitle">DATABASE</h1> */}
          <h2 id="dbheadersentence">350+ Projects</h2>
        </div>
        <img id="dbheadergrid" src={headergrid} alt="Header Grid" />{" "}

        <div id="filteredtagsdiv">
          {/* <FilteredTags
            selectedFilters={selectedFilterTags}
            onFilterTagRemove={handleFilterTagRemove}
            tags={selectedFilterTags}
            handleTagDelete={handleFilterTagRemove}
          /> */}
        </div>
      </div>
        <div id="projectfilter">
      <Suspense fallback={<div>Loading...</div>}>
        <ProjectFilter
          id="catfilter"
          onFilterChange={handleFilterChange}
          selectedFilters={selectedFilters}
          numDisplayedProjects={numDisplayedProjects}
          handleCheckboxChange={handleCheckboxChange}
        />
      </Suspense>
</div>

      <div className="project-grid-container">
        <div className="project-grid" style={filterSectionStyle}>
          {currentProjects.map((project) => (
            <Suspense fallback={<div>Loading...</div>} key={project.Name}>
              <ProjectCard
                project={project}
                onClick={() => handleProjectClick(project.Name)}
              />
            </Suspense>
          ))}
        </div>
        {noResults && (
          <div className="no-results-message">
            Sorry, no projects match your filter criteria.
          </div>
        )}
      </div>
      <div id="paginationdiv">
        <button
          id="paginationkey"
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          Previous
        </button>
        {Array.from(
          { length: Math.ceil(sortedProjects.length / projectsPerPage) },
          (_, index) => {
            if (index < 3) {
              return (
                <button
                  id="paginationkey"
                  key={index}
                  onClick={() => handlePageChange(index + 1)}
                  className={currentPage === index + 1 ? "active" : ""}
                >
                  {index + 1}
                </button>
              );
            }

            if (
              index >= currentPage - 2 &&
              index <= currentPage + 2 &&
              index < Math.ceil(sortedProjects.length / projectsPerPage) - 3
            ) {
              return (
                <button
                  id="paginationkey"
                  key={index}
                  onClick={() => handlePageChange(index + 1)}
                  className={currentPage === index + 1 ? "active" : ""}
                >
                  {index + 1}
                </button>
              );
            }

            if (
              index ===
                Math.ceil(sortedProjects.length / projectsPerPage) - 4 &&
              currentPage <
                Math.ceil(sortedProjects.length / projectsPerPage) - 3
            ) {
              return <span>...</span>;
            }

            if (
              index ===
              Math.ceil(sortedProjects.length / projectsPerPage) - 1
            ) {
              return (
                <button
                  id="paginationkey"
                  key={index}
                  onClick={() => handlePageChange(index + 1)}
                  className={currentPage === index + 1 ? "active" : ""}
                >
                  {index + 1}
                </button>
              );
            }

            return null;
          }
        )}
        <button
          id="paginationkey"
          disabled={
            currentPage === Math.ceil(sortedProjects.length / projectsPerPage)
          }
          onClick={() => handlePageChange(currentPage + 1)}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ProjectGrid;


