import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import RwaworldApi from "../api";
import "../Stylesheets/SearchBar.css";

const SearchBar = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSuggestions = async () => {
      try {
        const suggestions = await RwaworldApi.getAutocompleteSuggestions(searchTerm);
        setSuggestions(suggestions);
      } catch (error) {
        console.error('Error fetching autocomplete suggestions:', error);
      }
    };

    if (searchTerm) {
      fetchSuggestions();
    } else {
      setSuggestions([]);
    }
  }, [searchTerm]);

  const handleSearch = async (e) => {
    e.preventDefault();

    try {
      const projectData = await RwaworldApi.getProjectByNameOrCategory(searchTerm);
      
      if (projectData && projectData.name) {
        navigate(`/projects/${projectData.name}`);
        setSearchTerm("");
      } else {
        console.log("Project not found.");
      }
    } catch (error) {
      console.error("Error searching for project:", error);
    }
  };

  return (
    <form onSubmit={handleSearch}>
      <input
        id="search-bar"
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="Search for a project..."
        list="suggestions"
      />
      <datalist id="suggestions">
        {suggestions.map((suggestion, index) => (
          <option key={index} value={suggestion} />
        ))}
      </datalist>
      {/* <button id="search-button" type="submit">Search</button> */}
    </form>
  );
};

export default SearchBar;
