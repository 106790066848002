

import React, { useState, useEffect } from 'react';
import RwaworldApi from "../api";
import '../Stylesheets/Newsletter.css';



const Newsletter = () => {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const data = await RwaworldApi.getNewsletter(); // Fetch articles using the API function
        // console.log("This is the data", data);

        // Extract articles from the data object
        const extractedArticles = data.rss.channel[0].item.map(item => ({
          title: item.title[0],
          description: item.description[0],
          link: item.link[0],
          pubDate: item.pubDate[0],
          content: item['content:encoded'][0]
        }));
// console.log(data.rss.channel[0])
        setArticles(extractedArticles);
      } catch (error) {
        console.error('Error fetching articles:', error);
      }
    };

    fetchArticles();
  }, []);

  return (
    <div id='newsletterdiv'>
      {/* <h1 id='newsletterheader'>Newsletter</h1> */}
      <iframe
  src="https://embeds.beehiiv.com/b43bea16-a8e8-4117-9bf7-9bb2e8b9bbe7"
  data-test-id="beehiiv-embed"
  width="100%"
  height="320"
  style={{
    borderRadius: '4px',
    border: '2px solid #e5e7eb',
    margin: 0,
    backgroundColor: '#efedf8',
    maxWidth: '90%',
    marginTop: "5%",
  }}
></iframe>

      <ul>
        {articles.map((article, index) => (
          <li id='articlebox' key={index}>
            <a href={article.link} target="_blank" rel="noopener noreferrer">
              {article.title} <p>{article.description}</p>            <hr></hr>

            </a>

           
            {/* <p>{article.content}</p> */}
            {/* <p>Published Date: {article.pubDate}</p> */}
            {/* Render other properties as needed */}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Newsletter;


