import React from "react";
import "../Stylesheets/PartnershipsAndUsers.css";
import partnerships from "../Assets/Partners.jpg";
import usedby from "../Assets/Trusted.png";

const PartnershipsAndUsers = () => {
  return (
    <div>       <h1 id="homepageherotitle">Our Partners</h1>

    <div className="main-container">
      <div className="content-container">
        <div className="partnerships-box">
          <img src={partnerships} alt="Partnerships" />
        </div>
        <div className="users-box">
          <img src={usedby} alt="Used By" />
        </div>
      </div>
    </div></div>
  );
};

export default PartnershipsAndUsers;
