
import React from 'react';
import "../Stylesheets/Homepage.css"

const handleOverlayClick = (event, onClose) => {
    if (event.target === event.currentTarget) {
      onClose(); 
    }
  };
  

const NewsletterPopup = ({onClose}) => {
  return (
    <div className="popup-overlay" onClick={(event) => handleOverlayClick(event, onClose)}>
      <div className="popup-content">
        {/* <button id='popupclosebutton' onClick={onClose}>Close</button> */}
        <iframe

        id='newsletteriframe'
          src="https://embeds.beehiiv.com/b43bea16-a8e8-4117-9bf7-9bb2e8b9bbe7"
          data-test-id="beehiiv-embed"
          width="700"
          height="320"
          frameBorder="0"
          scrolling="no"
          style={{
            borderRadius: '4px',
            // border: '2px solid #e5e7eb',
            margin: '0',
            backgroundColor: 'transparent',
          }}
        ></iframe>
      </div>
    </div>
  );
};

export default NewsletterPopup;
