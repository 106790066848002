// import React, { useState } from "react";
// import Articles from "./Articles";
// import Newsletter from "../Newsletter/Newsletter";
// import BlogArticles from "./Blog";
// import "../Stylesheets/TabbedResearch.css";



// const TabPanel = ({ selectedTab }) => {
//   switch (selectedTab) {
//     case "articles":
//       return <Articles />;
//     case "newsletter":
//       return <Newsletter />;
//     case "blog":
//       return <BlogArticles />;
//     default:
//       return null;
//   }
// };

// const TabbedPage = () => {
//   const [selectedTab, setSelectedTab] = useState("articles");

//   const handleTabChange = (tab) => {
//     setSelectedTab(tab);
//   };

//   return (
//     <div>
//       <div id="tabbedresearchdiv">
//         <button id="tabbedresearchbutton" onClick={() => handleTabChange("articles")}>Articles</button>
//         <button id="tabbedresearchbutton"  onClick={() => handleTabChange("newsletter")}>Newsletter</button>
//         <button id="tabbedresearchbutton"  onClick={() => handleTabChange("blog")}>Blog</button>
//       </div>
//       <div>
//         <TabPanel selectedTab={selectedTab} />
//       </div>
//     </div>
//   );
// };

// export default TabbedPage;

import React, { useState } from "react";
import Articles from "./Articles";
import Newsletter from "../Newsletter/Newsletter";
import BlogArticles from "./Blog";
import "../Stylesheets/TabbedResearch.css";

const TabPanel = ({ selectedTab }) => {
  switch (selectedTab) {
    case "articles":
      return <Articles />;
    case "newsletter":
      return <Newsletter />;
    case "blog":
      return <BlogArticles />;
    default:
      return null;
  }
};

const TabbedPage = () => {
  const [selectedTab, setSelectedTab] = useState("articles");

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

//   return (
//     <div>
//       <div id="tabbedresearchdiv">
//         <button
//         id="tabbedresearchbutton"
//           style={{
//             textDecoration: selectedTab === "articles" ? "underline" : "none"
//           }}
//           onClick={() => handleTabChange("articles")}
//         >
//           Articles
//         </button>
//         <div className="divider"></div> {/* Vertical divider */}
//         <button
//         id="tabbedresearchbutton"
//           style={{
//             textDecoration: selectedTab === "newsletter" ? "underline" : "none"
//           }}
//           onClick={() => handleTabChange("newsletter")}
//         >
//           Newsletter
//         </button>
//         <div className="divider"></div> {/* Vertical divider */}

//         <button
//         id="tabbedresearchbutton"
//           style={{
//             textDecoration: selectedTab === "blog" ? "underline" : "none"
//           }}
//           onClick={() => handleTabChange("blog")}
//         >
//           Blog
//         </button>
//       </div>
//       <div>
//         <TabPanel selectedTab={selectedTab} />
//       </div>
//     </div>
//   );
return (
    <div>
      <div id="tabbedresearchdiv">
        <button
          id="tabbedresearchbutton"
          className={selectedTab === "articles" ? "active" : ""}
          onClick={() => handleTabChange("articles")}
        >
          Articles
        </button>
        <div className="divider"></div> {/* Vertical divider */}
        <button
          id="tabbedresearchbutton"
          className={selectedTab === "newsletter" ? "active" : ""}
          onClick={() => handleTabChange("newsletter")}
        >
          Newsletter
        </button>
        <div className="divider"></div> {/* Vertical divider */}
        <button
          id="tabbedresearchbutton"
          className={selectedTab === "blog" ? "active" : ""}
          onClick={() => handleTabChange("blog")}
        >
          Blog
        </button>
      </div>
      <div>
        <TabPanel selectedTab={selectedTab} />
      </div>
    </div>
  );
};

export default TabbedPage;


