import React from 'react';
import '../Stylesheets/About.css'; 

const About = () => {
  return (
    <div className="insights-container">
      <div className="section">
        <h1 className="section-title-top">About Us</h1>
        <h2 id='h2s'>Our Mission</h2>
        <p className="section-text">
        RWA World is your front page for tokenized assets. We're dedicated to bringing clarity and transparency to the rapidly evolving landscape of real-world asset (RWA) tokenization. We strive to be the industry standard for research, categorization, and analysis of tokenization trends and market developments. We aim to empower investors, institutions, and innovators with actionable intelligence on the convergence of traditional and digital asset markets.
        </p>
        <h2 id='h2s'>What We Offer</h2>

        <p className="section-text">
        We host the largest publicly available database of tokenized asset issuers and initiatives, tracking over 400 entities. Our comprehensive asset taxonomy provides in-depth data on each asset's structure and key characteristics. We deliver curated intelligence reports, offering expert analysis on real-world asset tokenization opportunities and trends. Our widely-read weekly newsletter and data-driven analytics keep you informed with real-time updates on the latest developments in the asset tokenization industry.
        </p>
        <h2 id='h2s'>Why RWA World</h2>

        <p className="section-text">
        RWA World combines expertise in traditional finance with a deep understanding of tokenized and distributed ledger systems. Our proprietary unified taxonomy for asset and issuer types has attracted readership from major institutions, including Coinbase, Kraken, VanEck, the European Central Bank, and BlackRock. We provide unparalleled insights into digital representations of physical assets like tokenized real estate and blockchain-based financial assets, enabling informed decision-making across this dynamic new ecosystem.
        </p>
        <h2 id='h2s'>Our Expertise</h2>
        <p className="section-text">
        Our team of experts specializes in tokenized asset intelligence, offering deep insights into RWA tokenization initiatives, market trends, and regulatory developments. We analyze tokenized asset trends and implications and deliver exclusive insights from key industry stakeholders and builders, helping document and define the evolving landscape of digital asset market infrastructure. From institutional-grade tokenization research to simplified at-a-glance project data, RWA World is your trusted source for navigating the future of asset tokenization.
        </p>

      </div>
      <div className="section">
        <h2 className="section-title-bottom">Why Tokenized Assets?</h2>
        <p className="section-text">
        The tokenization of real-world assets represents a paradigm shift in global financial infrastructure, offering a much-needed overhaul to systems that are approaching six decades of use. This blockchain-based approach to asset management brings numerous advantages:
        </p>
        <p className="section-text">
        <b>Enhanced Transactional Efficiency:</b> Tokenization streamlines asset transfers, enabling near-instantaneous settlement and reducing intermediary costs. This efficiency can significantly impact liquidity and market accessibility across various asset classes.
        </p>
        <p className="section-text">
        <b>Increased Transparency:</b> Blockchain technology provides an immutable record of ownership and transactions, fostering greater operational and market transparency. This enhanced visibility can lead to more efficient price discovery and reduced information asymmetry.
        </p>
        <p className="section-text">
        <b>Improved Risk Management:</b> Tokenization can mitigate counterparty risk through smart contracts and decentralized systems, potentially reducing the need for traditional clearing houses and offering new models of trust in financial transactions.
        </p>
        <p className="section-text">
        <b>Modernized Asset Management:</b> As traditional financial rails increasingly show their age, tokenization propels asset management firmly into the 21st century. It offers new possibilities for fractional ownership, programmable assets, and automated compliance, opening up new avenues for innovation in financial products and services.
        </p>
      </div>
    </div>
  );
};

export default About;
