import "./App.css";
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useLocation } from 'react-router-dom';

import Navbar from "./Navigation/Navbar";
import Footer from "./Navigation/Footer";
import DarkModeToggle from "./DarkModeToggle";
import { DarkModeProvider } from "./DarkModeContext";
import ReactGA from 'react-ga';


import Routes from "./Routes";

ReactGA.initialize('G-N0JFHW7C8R');


function App() {
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem("isDarkMode") === "true"
  );
  // const location = useLocation();

  // useEffect(() => {
  //   ReactGA.pageview(location.pathname + location.search);
  // }, [location]);


  const toggleDarkMode = () => {
    const newDarkMode = !isDarkMode;
    setIsDarkMode(newDarkMode);
    localStorage.setItem("isDarkMode", newDarkMode.toString());
  };

  return (
    <Router>
      <DarkModeProvider value={{ isDarkMode, toggleDarkMode }}>
        {/* <DarkModeToggle/> */}
        <Navbar />
        <Routes />

        <Footer />
      </DarkModeProvider>
    </Router>
  );
}

export default App;
