import axios from "axios";


const REACT_APP_BASE_URL = "https://backend-er5j.onrender.com"
const BASE_URL = REACT_APP_BASE_URL || "http://localhost:3001";
//  const BASE_URL =  "http://localhost:3001";

/** API Class.
 *
 * Static class tying together methods used to get/send to to the API.
 * There shouldn't be any frontend-specific stuff here, and there shouldn't
 * be any API-aware stuff elsewhere in the frontend.
 
 */

class RwaworldApi {
  // the token for interactive with the API will be stored here.
  static token;

  static async request(endpoint, data = {}, method = "get") {
    console.debug("API Call:", endpoint, data, method);

    //there are multiple ways to pass an authorization token, this is how you pass it in the header.
    //this has been provided to show you another way to pass the token. you are only expected to read this code for this project.
    const url = `${BASE_URL}/${endpoint}`;
    const headers = { Authorization: `Bearer ${RwaworldApi.token}` };
    const params = method === "get" ? data : {};

    try {
      return (await axios({ url, method, data, params, headers })).data;
    } catch (err) {
      console.error("API Error:", err.response);
      let message = err.response.data.error.message;
      throw Array.isArray(message) ? message : [message];
    }
  }

static setupInterceptors() {
    axios.interceptors.request.use(
      (config) => {
        if (RwaworldApi.token) {
          config.headers["Authorization"] = `Bearer ${RwaworldApi.token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }





  static async addAllProjectstoDB() {
    let res = await this.request("projects/");

    // console.log(res);
    return res;
  }

  static async getAllProjects() {
    let res = await this.request("projects/")
    // console.log("These are the projects", res);
    return res;
  }


  /** Get details on a project by name. */

  // Get project by name
  static async getProject(Name) {
    console.log(Name)
    let res = await this.request(`projects/${Name}`);
    console.log(res);

    return res;
  }

  static async getProjectByIssuerCat(issuer_asset_category) {
    let res = await this.request(`taxonomy/${issuer_asset_category}`)
    console.log(res);

    return res;
  }

  
// static async getAutocompleteSuggestions(searchTerm) {
//   try {
//     const res = await axios.get(`${BASE_URL}/search?term=${searchTerm}`);
//     return res.data; 
//   } catch (error) {
//     console.error('Error fetching autocomplete suggestions:', error);
//     throw error;
//   }
// }

static async getAutocompleteSuggestions(searchTerm) {
  try {
    const res = await axios.get(`${BASE_URL}/projects/search?term=${searchTerm}&autocomplete=true`);
    return res.data.suggestions; // Assuming suggestions are directly returned as an array
  } catch (error) {
    console.error('Error fetching autocomplete suggestions:', error);
    throw error;
  }
}



static async searchProjects(searchTerm) {
  try {
    let res = await this.request(`search`, { searchTerm }); 
    return res;
  } catch (error) {
    console.error('Error searching projects:', error);
    throw error; 
  }
}

static async getProjectByNameOrCategory(projectNameOrCategory) {
  console.log("This function has been called: getProjectByNameOrCategory");
  let res = await this.request(`projects/name-or-category/${projectNameOrCategory}`);

  return res.project;
}

static async getNewsletter() {
  try {
    const res = await axios.get(`${BASE_URL}/newsletter`);
    console.log(res)
    return res.data;
  } catch (error) {
    console.error('Error fetching newsletter:', error);
    throw error;
  }
}

static async getArticles() {
  try {
    const res = await axios.get(`${BASE_URL}/articles`);
    console.log(res)
    return res.data;
  } catch (error) {
    console.error('Error fetching articles:', error);
    throw error;
  }
}

static async getBlog() {
  try {
    const res = await axios.get(`${BASE_URL}/blog`);
    console.log(res)
    return res.data;
  } catch (error) {
    console.error('Error fetching articles:', error);
    throw error;
  }
}



static async getBlogByTitle(title) {
  try {
    const res = await axios.get(`${BASE_URL}/blog?title=${title}`);
    return res.data;
  } catch (error) {
    console.error('Error fetching articles by title:', error);
    throw error;
  }
}
static async getArticlesByTitle(title) {
  try {
    const res = await axios.get(`${BASE_URL}/articles?title=${title}`);
    return res.data;
  } catch (error) {
    console.error('Error fetching articles by title:', error);
    throw error;
  }
}

static async getBlogByPageID(pageID) {
  try {
    const res = await axios.get(`${BASE_URL}/blog/${pageID}`);
    return res.data;
  } catch (error) {
    console.error('Error fetching article by page ID:', error);
    throw error;
  }
}
static async getArticleByPageID(pageID) {
  try {
    const res = await axios.get(`${BASE_URL}/articles/${pageID}`);
    return res.data;
  } catch (error) {
    console.error('Error fetching article by page ID:', error);
    throw error;
  }
}

// function to get metadata from webflow
static async getBlogMetadataByPageID(pageID) {
  try {
    const res = await axios.get(`${BASE_URL}/blog/metadata/${pageID}`);
    return res.data;
  } catch (error) {
    console.error('Error fetching article metadata by page ID:', error);
    throw error;
  }
}
// function to get metadata from webflow
static async getArticleMetadataByPageID(pageID) {
  try {
    const res = await axios.get(`${BASE_URL}/articles/metadata/${pageID}`);
    return res.data;
  } catch (error) {
    console.error('Error fetching article metadata by page ID:', error);
    throw error;
  }
}


static async fetchBlogImageByAssetId(assetId) {
  try {
    const response = await axios.get(`${BASE_URL}/blog/images/${assetId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching image data:", error);
    throw new Error("Failed to fetch image data");
  }
}
static async fetchImageByAssetId(assetId) {
  try {
    const response = await axios.get(`${BASE_URL}/articles/images/${assetId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching image data:", error);
    throw new Error("Failed to fetch image data");
  }
}


  



}

export default RwaworldApi;
