import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Homepage from "./LandingPage/Homepage";
import ProjectGrid from "./Project Components/ProjectGrid";
import ContactPage from "./ContactPage/Contact";
import GoogleDoc from "./Research/TestResearch";
import TermsOfService from "./AboutPage/TermsOfService";

import ProjectCard from "./Project Components/ProjectCard";
import IndividualProject from "./Project Components/ProjectPage";
import About from "./AboutPage/About";
import PrivacyPolicy from "./AboutPage/PrivacyPolicy";
import Newsletter from "./Newsletter/Newsletter";
// import ArticlePage from "./NewsletterArticle"
import DocumentViewer from "./Research/GoogleDocs1";
import CategoryPage from "./Project Components/Taxonomy";
import Articles from "./Research/Articles";
import ArticleCard from "./Research/ArticleCard";
import ArticlePage from "./Research/ArticlePage";
import GoogleAnalytics from "./GoogleAnalyticsPageTracker";
import TabbedResearch from "./Research/TabbedResearch";
import AnnualReport from "./Research/YearlyReport2023";
import Watches from "./Subcategories/watches";

function UserRoutes() {
  return (
    <div>
      <Routes>
        <Route element={<GoogleAnalytics />} />{" "}
        <Route exact path="/" element={<Homepage />} />
        <Route exact path="/projects" element={<ProjectGrid />} />
        <Route
          exact
          path="/projects/:projectName"
          element={<IndividualProject />}
        />
        <Route exact path="/contact" element={<ContactPage />} />
        <Route exact path="/about" element={<About />} />
        {/* <Route exact path="/research" element={<DocumentViewer />} /> */}
        <Route exact path="/termsofservice" element={<TermsOfService />} />
        <Route exact path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route exact path="/newsletter" element={<Newsletter />} />
        <Route exact path="/taxonomy/:category" element={<CategoryPage />} />
        <Route exact path="/taxonomy/collectibles-and-alternatives/watches" element={<Watches />} />
        <Route exact path="/research" element={<TabbedResearch />} />
        <Route exact path="/research/annualreport" element={<AnnualReport />} />
        <Route exact path="/research/:pageId" element={<ArticlePage />} />
      </Routes>
    </div>
  );
}

export default UserRoutes;
