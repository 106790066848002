import React from "react";
import "../Stylesheets/Footer.css";
import { Link } from "react-router-dom";
import footerlogo from "../Assets/footerlogo.png";
import medium from "../Assets/medium.png";
import linkedin from "../Assets/linkedin.png";
import twitter from "../Assets/twitter.png";
import footer from "../Animations/footer.json";
import Lottie from 'lottie-react';
import linktree from "../Assets/linktree.webp"

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer-column">
        <div className="footer-content">
          <img id="logo" src={footerlogo} alt="logo" />
          <iframe
            src="https://embeds.beehiiv.com/798f56ec-7472-416d-aed2-0bc41f1ab282"
            data-test-id="beehiiv-embed"
            width="100%"
            height={320}
            frameBorder={0}
            scrolling="no"
            style={{
              borderRadius: '4px',
              margin: '0',
              backgroundColor: 'transparent',
            }}
          />
        </div>
        <div className="footer-content">
          <p id="copyright">© 2023-2024 Real World Asset Intelligence</p>
        </div>
      </div>
      <div className="footer-column lottie-column">
        <div className="footer-content">
          <Lottie animationData={footer} />
        </div>
      </div>
      <div className="footer-column">
        <div className="footer-content">
          <div className="footer-links">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/projects">Database</Link>
              </li>
              {/* <li>
                <Link to="/about">Taxonomy</Link>
              </li> */}
              {/* <li>
                <Link to="/projects">Projects</Link>
              </li> */}
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </div>
        </div>
        {/* <div className="footer-content social-media">
          <img src={linkedin} alt="linkedin" id="linkedin" />
          <img src={twitter} alt="twitter" id="twitter" />
          <img src={medium} alt="medium" id="medium" />
        </div> */}
        <div className="footer-content social-media">
  <a href="https://www.linkedin.com/company/rwaworld/" target='_blank'>
    <img src={linkedin} alt="linkedin" id="linkedin" />
  </a>
  <a href="https://twitter.com/RwaWorld" target='_blank'>
    <img src={twitter} alt="twitter" id="twitter" />
  </a>
  <a href="https://medium.com/rwa-world" target='_blank'>
    <img src={medium} alt="medium" id="medium" />
  </a>
  <a href="https://linktr.ee/rwaworld" target='_blank'>
    <img src={linktree} alt="linktree" id="linktree" />
  </a>
</div>

        <div className="footer-content" id="privacy-terms">
          <a href="/privacypolicy" id="privacy" target='_blank'>
            Privacy Policy
          </a>
          <a href="termsofservice" id="terms" target='_blank'>
            Terms of Service
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;


