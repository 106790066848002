
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom"; 
import "../Stylesheets/HeroSection.css"; 
import heroimage1 from "../Assets/HeroSectionAnalytics1.png";
import heroimage2 from "../Assets/HeroSectionAnalytics2.png";
import heroimage1dark from "../Assets/heroimagedark1.jpg"
import heroimage2dark from "../Assets/heroimagedark2.jpg"
import { useDarkMode } from '../DarkModeContext';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HeroSection = () => {

  const { isDarkMode, toggleDarkMode } = useDarkMode();

  const heroImage1Src = isDarkMode ? heroimage1dark : heroimage1;
  const heroImage2Src = isDarkMode ? heroimage2dark : heroimage2;

  const boxes = [
    {
      id: 1,
      title: "Comprehensive Asset Taxonomy",
      description: "Dive deeper with data on each asset's structure and key characteristics.",
      link: "/taxonomy/equity-and-stocks",
    },
    {
      id: 2,
      title: "Key Insights, Simplified",
      description: "Discover essential project data in an easy-to-understand, at-a-glance format.",
      link: "/projects",
    },
    {
      id: 3,
      title: "Always Current",
      description: "Timely and relevant information at your fingertips with daily updates.",
      link: "https://twitter.com/RwaWorld",
    },
    {
      id: 4,
      title: "Bi-Weekly Intelligence Reports",
      description: "Stay ahead of the curve with in-depth reports and analysis on real-world asset opportunities and trends.",
      link: "/research",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div id="realtimeanalyticsdiv">
      <h1 id="homepageherotitle">Real-Time Analytics</h1>
      <div className="hero-container ">
        <div className="hero-row">
          <Link to="/taxonomy/equity-and-stocks" className="hero-box hero-small"> {/* Wrap the box with Link and set to="/url1" */}
            <div className="hero-box-content hidden md:flex md:flex-col md:gap-4">
              <h2 className="heroboxheaders">Comprehensive Asset Taxonomy</h2>
              <p className="heroboxparagraphs">
                Dive deeper with data on each asset's structure and key
                characteristics.
              </p>
            </div>
          </Link>

          <Link to="/projects" className="hero-box hero-small"> {/* Wrap the box with Link and set to="/url2" */}
            <div className="hero-box-content hidden md:flex md:flex-col md:gap-4">
              <h2 className="heroboxheaders">Key Insights, Simplified</h2>
              <p className="heroboxparagraphs">
                Discover essential project data in an easy-to-understand,
                at-a-glance format.
              </p>
            </div>
          </Link>

          <div className="hero-box hero-wide">
          <img src={heroImage1Src} alt="heroimage1" />
          </div>
        </div>

        <div className="hero-row">
          <div id="heroimage2" className="hero-box hero-wide">
          <img  src={isDarkMode ? heroimage2dark : heroimage2} alt="heroimage2" />          </div>

          <Link to="https://twitter.com/RwaWorld" target='_blank' className="hero-box hero-small"> {/* Wrap the box with Link and set to="/url3" */}
            <div className="hero-box-content hidden md:flex md:flex-col md:gap-4" id="homepageherobox3">
              <h2 className="heroboxheaders">Always Current</h2>
              <p className="heroboxparagraphs">
                Timely and relevant information at your fingertips with daily
                updates.{" "}
              </p>
            </div>
          </Link>

          <Link to="/research" className="hero-box hero-small"> 
            <div className="hero-box-content hidden md:flex md:flex-col md:gap-4">
              <h2 className="heroboxheaders">Bi-Weekly Intelligence Reports</h2>
              <p className="heroboxparagraphs">
                Stay ahead of the curve with in-depth reports and analysis on
                real-world asset opportunities and trends{" "}
              </p>
            </div>
          </Link>
        </div>


         {/* Render carousel for mobile screens */}
         {/* <div className="md:hidden">
          <Slider {...settings}>
            {boxes.map((box) => (
              <div key={box.id} className="hero-box">
                <Link to={box.link} className="hero-box-content">
                  <h2 className="heroboxheaders">{box.title}</h2>
                  <p className="heroboxparagraphs">{box.description}</p>
                </Link>
              </div>
            ))}
          </Slider>
        </div> */}
      </div>
    </div>
  );


};

export default HeroSection;
