import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from 'react-ga';

function PageViewTracker() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return null; // Since this component doesn't render anything
}

export default PageViewTracker;
