
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "../Stylesheets/Taxonomy.css";
import RwaworldApi from "../api";
import CatProjectCard from "./CatProjectCard";
import ProjectCarousel from "./ProjectCarousel";
import EnhancedTable from "./ProjectCategoryTable";
import { Link } from 'react-router-dom';


const formatCategoryName = (name) => {
  const exceptions = ["and"]; // List of exceptions

  return name
    .replace(/-/g, " ")
    .split(" ")
    .map((word, index) => {
      if (index === 0 || !exceptions.includes(word.toLowerCase())) {
        return word.charAt(0).toUpperCase() + word.slice(1); // Capitalize the word if it's not an exception or the first word
      } else {
        return word; 
      }
    })
    .join(" ");
};

const CategoryPage = () => {
  const { category } = useParams(); // Get the dynamic parameter from the URL
  const formattedCategory = formatCategoryName(category); // Format the category name
  const [catProjects, setCatProjects] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await RwaworldApi.getProjectByIssuerCat(
          formattedCategory
        );
        console.log("API Response:", response); // Log to see the response
        setCatProjects(response);
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchData();
  }, [formattedCategory]);

  const categories = [
    {
      categoryName: "Equity and Stocks",
      description:
        "Equity and stocks represent ownership in a company, offering investors a stake in the corporation's assets and earnings. This asset class is a cornerstone of the financial markets, providing capital to businesses for growth while offering investors the potential for profit through dividends and share price appreciation. Tokenization has further democratized access to equity markets, enabling smaller investors to participate in companies' financial growth worldwide. This shift not only broadens investment opportunities but also enhances market liquidity and efficiency, making equity investments more accessible and appealing to a global audience.",
      subcategories: ["Public Equities", "Private Equities", "SME Equities"],
      color: "#f18f7a" 
    },
    {
      categoryName: "Public Debt Instruments",
      description:
        "Public debt is essential in our modern global financial system, enabling financing and funding schemes that would otherwise be impossible. Public debt encompasses government-issued securities like bonds and bills, which represent loans made by investors to national and local governments. This category of debt is essential for financing public projects, varies in maturity, yield, and risk, and offers a reliable, lower-risk option for investors.",
      subcategories: [
        "Government Bonds (Long-Term)(US)",
        "Government Bonds (Short-Term)(US)",
        "Government Bonds (non-US)",
        "Municipal Bonds",
        "Reverse Repos",
      ],
      color: "#DAF7A6", 
    },
    {
      categoryName: "Private Debt Instruments",
      description:
        "Private debt is one of the oldest financial instruments known to humanity. It refers to loans and debt financing provided by non-bank institutions or investors, like credit card companies. This type of debt is generally not traded on public exchanges and involves custom terms and conditions relative to the borrower’s needs. As the global economy grows and diversifies, additional private lending channels will be required to facilitate necessary capital, with tokenization underpinning many of the new solutions coming to market.",
      subcategories: [
        "Corporate Bonds (US)",
        "Corporate Bonds (non-US)",
        "Private Debt",
        "Structured Finance",
        "SME Debt",
      ],
      color: "#a0f17a",

    },
    {
      categoryName: "Real Estate",
      description:
        "Real estate is a foundational asset class, representing safety and security in physical form and within investor portfolios. Traditionally characterized by high value and low liquidity, real estate investments offer a unique blend of capital appreciation and income generation potential. The desire for direct real estate exposure is evidenced by the rapid rise of Airbnb, which now plays a significant role in the short-term rental market in the U.S. Tokenization has begun to democratize access to the global real estate market while enhancing available liquidity, making for an overall more flexible and attractive investment opportunity.",
      subcategories: [
        "Real Estate (General)",
        "Real Estate (Commercial)",
        "Mortgage",
        "Land",
      ],
      color: "#7af1cb",
 
    },
    {
      categoryName: "Commodities and Precious Metals",
      description:
        "Tokenization has begun to change the face of global commodities, offering global investors, many for the first time, exposure to this powerful asset class. This introduction has increased liquidity, market accessibility, and transparency. By converting physical commodities into digital tokens, investors gain access to fractional ownership, making market entry more accessible than ever before. The tokenization of global commodities is tracing the trend of computerization from decades past – this trend is poised to accelerate into 2024.",
      subcategories: [
        "Gold and Silver",
        "Other Precious Metals",
        "Industrial and Strategic Metals and Minerals",
        "Energy Commodities",
        "Agricultural Commodities",
      ],
      color: "#6ff6ef",
 
    },
    {
      categoryName: "Insurance and Claims",
      description:
        "Insurance is a cornerstone of any risk management and financial planning strategy. This segment of the global economy has been undergoing significant digital transformations over the past decades. Internet-of-things devices provide more real-time data than ever, creating a mixed blessing for an industry already inundated with facts requiring case-by-case analysis of the associated variables. Tokenization is the latest force poised to streamline operations, enhance transparency, and improve the overall efficiency of counterparty risk mitigation. By paving the way for more accurate risk assessments, fraud prevention, and standardized data processing, tokenization benefits policyholders and insurers significantly.",
      subcategories: [
        "Natural Disaster Claims",
        "Agricultural Claims",
        "Travel Insurance Claims",
        "Credit and Financial Risk Insurance",
        "Specialty Claims",
      ],
      color: "#c1b1c6",
 
    },
    {
      categoryName: "Collectibles and Alternatives",
      description:
        "Collectibles range from tangible items like vintage baseball cards and antiques to digital assets like NFTs. Enthusiasts value their collectibles for their rarity, condition, and personal or cultural significance. They are influenced by a range of factors, including budget, storage requirements, time commitment, and whether they view their collection as an investment or a hobby. Traditional collectibles offer a physical, often nostalgic experience but require space and special conditions for preservation. Digital collectibles, such as in-game items and NFTs, exist virtually, allowing global accessibility and ease of access. The divide between traditional and digital collectibles highlights the unique experiences and values shaped by their inherent characteristics, and their interrelation reflects the evolving landscape of collectible assets and tokenization.",
      subcategories: [
        "Watches",
        "Music",
        "Physical Art",
        "Sports Memorabilia",
        "Historical Artifacts",
        "Automotive and Vehicles",
        "Media and Entertainment",
      ],
      color: "#f6ef6f",
 
    },
    {
      categoryName: "Financial Services and Payments",
      description:
        "The efficient movement of money is essential to global supply chains and financial systems. Financial services and payments facilitate these movements, comprising an entire economic sector on the back of facilitating economic activity itself. Any business operation's capital cost is ultimately paid to a counterparty, and these counterparties have already begun to experiment with the emerging technologies underpinning tokenization. From cross-border payments to venture capital, tokenizing payments and financial services represents the unlocking of immense economic and financial potential.",
      subcategories: [
        "Cross-border Payments",
        "Remittances",
        "Receivables",
        "Venture Capital",
        "Fiat",
      ],
      color: "#ca5cd6",
 
    },
    {
      categoryName: "Sustainable and Responsible Investments",
      description:
        "Sustainable and Responsible Investments focus on generating social and environmental impact alongside financial returns. This investment philosophy integrates ESG (Environmental, Social, and Governance) criteria into decision-making processes, aiming to support projects and companies that contribute positively to the world. Tokenization expands access to these types of investments, allowing for fractional ownership and democratizing investment in previously unviable funds or projects. This approach aligns investor values with investment portfolios, fostering a more sustainable and equitable global economy.",
      subcategories: ["ESG", "Carbon Credits", "Agriculture"],
      color: "#6fb9f6" 
    },
    {
      categoryName: "Funds and Investment Vehicles",
      description:
        "Investment funds have a comparatively young history, with more sophisticated financial instruments sporting an age of under 100 years. Modern funds and investment vehicles vary from simple-to-understand to esoteric and immensely complex webs of interrelated instruments. Funds and investment vehicles are generally highly regulated and transparent, making them incredibly useful tools for diversifying a portfolio, offering greater financial security and long-term asset protection. Tokenization is poised to unlock unprecedented levels of global financial liquidity for investment funds, ushering in a new era of global investment accessibility.",
      subcategories: [
        "Private Funds",
        "Money Market (US)",
        "Private Capital Markets",
      ],
      color: "#ff3300",
 
    },
    {
      categoryName: "Physical Infrastructure",
      description:
        "Infrastructure is the tangible backbone of the global economy. These assets support daily life, facilitate trade, and drive economic growth. Traditionally, investment in physical infrastructure has been the domain of large institutional investors and governments due to the significant capital requirements and long time horizons. Tokenization presents an exciting way to fund and invest in critical infrastructure assets, breaking down barriers to entry and offering new opportunities for diversification and investment. By converting physical infrastructure assets into digital tokens, smaller investors can access this traditionally exclusive sector, promoting broader participation and democratization in infrastructure investment.",
      subcategories: [
        "Transportation Infrastructure",
        "Utility Infrastructure",
        "Public Facilities",
        "Green Infrastructure",
        "Digital Infrastructure",
      ],
      color: "#00cc00",
 
    },
    {
      categoryName: "Derivatives",
      description:
        "Derivatives are financial contracts whose value is derived from underlying assets such as stocks, bonds, commodities, currencies, interest rates, or market indexes. This category encompasses tokenized assets not directly backed by the underlying real-world assets themselves (such as synthetic on-chain assets) and assets lacking fundamental physical properties (such as legally recognized intellectual property.)",
      subcategories: ["Synthetics", "IP"],
      color: "#de6ff6",

    },
  ];

  const selectedCategory = categories.find(
    (cat) =>
      cat.categoryName.toLowerCase().replace(/ /g, "") ===
      category.toLowerCase().replace(/-/g, "")
  );

  if (!selectedCategory) {
    // Render something if the category is not found
    return <div>Category not found</div>;
  }

  return (
    <div>
      <div
        id="categorydiv"
        
      >
        <h1 id="categoryname" style={{ backgroundColor: selectedCategory.color }}>{selectedCategory.categoryName}</h1>
        <hr id="categorynamedivider"></hr>
        <p id="categorydescription">{selectedCategory.description}</p>

        <h2 id="subcategories">Subcategories:</h2>
        <ul id="subcategoriesul">
          {selectedCategory.subcategories.map((subcategory, index) => (
            <li id="subcategoriesitem" key={index}>
                     <Link id="subcategoriesitem" to={`/taxonomy/${category}/${subcategory}`}>
            {subcategory}
          </Link>
              <hr id="divider"></hr>
            </li>
     
          ))}
        </ul>
      </div>


<div id="projecttable">
        {/* Render the table component with catProjects as rows */}
        <div id="categorytable">
        {/* <EnhancedTable rows={catProjects} /> */}
        {catProjects.length > 0 && (
  <EnhancedTable rows={catProjects} />
)}

      </div></div>
    </div>
  );
};

export default CategoryPage;
