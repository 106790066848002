import { Fragment, useState, useEffect } from "react";
import "../Stylesheets/index.css";
import { Dialog, Disclosure, Popover, Transition } from "@headlessui/react";
import {
  ArrowPathIcon,
  Bars3Icon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
  XMarkIcon,
  HomeModernIcon,
} from "@heroicons/react/24/outline";
import {
  BanknotesIcon,
  BuildingLibraryIcon,
  ChevronDownIcon,
  Cog6ToothIcon,
  CubeTransparentIcon,
  GlobeAmericasIcon,
  PhoneIcon,
  PlayCircleIcon,
  PresentationChartBarIcon,
  PresentationChartLineIcon,
  ShieldCheckIcon,
  TicketIcon,
} from "@heroicons/react/20/solid";
import logo from "../Assets/RWA-02 (2).png";
import logodark from "../Assets/logodark.png"
import SearchBar from "../FilteredSearch/SearchBar";
import "../App.css";
import { useDarkMode } from '../DarkModeContext';
import DarkModeToggle from "../DarkModeToggle";



const categoryColors = [
  { category: "Equity and Stocks", color: "#f18f7a" },
  { category: "Bonds", color: "#DAF7A6" },
  { category: "- Asset-agnostic", color: "#a0f17a" },
  { category: "Real Estate", color: "#7af1cb" },
  { category: "Collectibles and Alternatives", color: "#f6ef6f" },
  { category: "Sustainable and Responsible Investments", color: "#6fb9f6" },
  { category: "Commodities and Precious Metals", color: "#6ff6ef" },
  { category: "Public Debt Instruments", color: "#de6ff6" },
  { category: "Financial Services and Payments", color: "#ca5cd6" },
  { category: "Insurance and Claims", color: "#c1b1c6" },
  { category: "Private Debt Instruments", color: "#99ff33" },
  { category: "Funds and Investment Vehicles", color: "#ff3300" },
  { category: "Physical infrastructure", color: "#00cc00" },
];

const products = [
  {
    name: "Equity & Stocks",
    description: "Public Equities, Private Equities, SME Equities",
    href: "/taxonomy/equity-and-stocks",
    icon: ChartPieIcon,
    backgroundColor: "#f18f7a",
  },
  {
    name: "Public Debt",
    description:
      "Government Bonds (Short, Long, Ex-US), Municipal Bonds, Reverse Repos",
    href: "/taxonomy/public-debt-instruments",
    icon: BuildingLibraryIcon,
    backgroundColor: "#DAF7A6",
  },
  {
    name: "Private Debt",
    description: "Corporate Bonds, Structured Finance, SME Debt",
    href: "/taxonomy/private-debt-instruments",
    icon: FingerPrintIcon,
    backgroundColor: "#a0f17a",
  },
  {
    name: "Real Estate",
    description: "General & Commercial, Mortage, Land",
    href: "/taxonomy/real-estate",
    icon: HomeModernIcon,
    backgroundColor: "#7af1cb",
  },
  {
    name: "Commodities",
    description: "Precious Metals, Energy, Agriculture",
    href: "/taxonomy/commodities-and-precious-metals",
    icon: PresentationChartBarIcon,
    backgroundColor: "#f6ef6f",
  },
  {
    name: "Insurance & Claims",
    description:
      "Natural Disaster, Agricultural, Travel, Credit & Finance Risk, Specialty",
    href: "/taxonomy/insurance-and-claims",
    icon: ShieldCheckIcon,
    backgroundColor: "#6fb9f6",
  },
  {
    name: "Collectibles & Alternatives",
    description:
      "Music, Physical, Sports Memorabilia, Artifacts, Automobiles, Media & Entertainment",
    href: "/taxonomy/collectibles-and-alternatives",
    icon: TicketIcon,
    backgroundColor: "#6ff6ef",
  },
  {
    name: "Financial Services & Payments",
    description: "Cross-border Payments, Remittances, Receivables, VC, Fiat",
    href: "/taxonomy/financial-services-and-payments",
    icon: BanknotesIcon,
    backgroundColor: "#de6ff6",
  },
  {
    name: "Sustainable Investments",
    description: "ESG, Carbon Credits, Agriculture",
    href: "/taxonomy/sustainable-and-responsible-investments",
    icon: GlobeAmericasIcon,
    backgroundColor: "#ca5cd6",
  },
  {
    name: "Funds & Investment Vehicles",
    description: "Private Funds, Money Market (US), Private Capital Markets",
    href: "/taxonomy/funds-and-investment-vehicles",
    icon: PresentationChartLineIcon,
    backgroundColor: "#c1b1c6",
  },
  {
    name: "Physical Infrastructure",
    description:
      "Transportation, Utilities, Public Facilities, Green Infra, Digital",
    href: "/taxonomy/physical-infrastructure",
    icon: Cog6ToothIcon,
    backgroundColor: "#99ff33",
  },
  {
    name: "Derivatives",
    description: "Synthetics, IP",
    href: "/taxonomy/derivatives",
    icon: CubeTransparentIcon,
    backgroundColor: "#ff3300",
  },
];

const callsToAction = [
  { name: "Go to Database", href: "#", icon: PlayCircleIcon },
  { name: "Explore Taxonomy", href: "#", icon: PhoneIcon },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navbar() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { isDarkMode, toggleDarkMode } = useDarkMode();

  // useEffect(() => {
  //   const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

  //   const updateDarkMode = () => {
  //     const isDarkMode = darkModeMediaQuery.matches;
  //     setIsDarkMode(isDarkMode);
  //   };
  
  //   updateDarkMode(); // Update dark mode initially
  
  //   const handleChange = () => {
  //     updateDarkMode();
  //   };
  
  //   darkModeMediaQuery.addEventListener('change', handleChange);
  
  //   return () => {
  //     darkModeMediaQuery.removeEventListener('change', handleChange);
  //   };
  // }, []);

  // const handleDarkModeToggle = (isDarkMode) => {
  //   setIsDarkMode(isDarkMode);
  // };

  return (
    <header>
      <nav
        className="mx-auto flex max-w-100% items-center justify-between p-6 lg:px-8 dark:text-white"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <a href="/" className="-m-4 p-3">
            <span className="sr-only">Your Company</span>
            {/* <img
              id="navbarlogo"
              style={{ width: "50%", height: "100%" }}
              className="navbarlogo"
              src={logo}
              alt=""
            /> */}
           {/* <picture>
    <source srcSet={isDarkMode ? logodark : logo} media="(prefers-color-scheme: dark)" />
    <img
      id="navbarlogo"
      style={{ width: "50%", height: "100%" }}
      className="navbarlogo"
      src={isDarkMode ? logodark : logo}
      alt=""
    />
  </picture>   */}
<picture>
  <source srcSet={isDarkMode ? logodark : logo} />
  <img src={isDarkMode ? logodark : logo} alt="Your Company Logo"    id="navbarlogo"
      style={{ width: "50%", height: "100%" }}
      className="navbarlogo"
     />
</picture>




          </a>
        </div>
        <div className="justify-between hidden lg:flex">
          <a
            id="navbarbutton"
            href="/projects"
            className="text-lg font-semibold leading-6 text-gray-900 dark:text-white mr-12"
          >
            Projects
          </a>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>

        <Popover.Group className="hidden lg:flex lg:gap-x-12">
          <Popover className="relative">
            <Popover.Button
              id="navbarbutton"
              className="flex items-center gap-x-1 text-lg font-semibold leading-6 text-gray-900 dark:text-white"
            >
              Taxonomy
              <ChevronDownIcon
                className="h-5 w-5 flex-none text-gray-400 dark:text-white"
                aria-hidden="true"
              />
            </Popover.Button>

            {/* <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >     */}

<div
  className="transition ease-out duration-200"
  style={{
    transitionProperty: "opacity, transform",
    transitionTimingFunction: "ease-out",
    transitionDuration: "200ms",
  }}
>
            
             {/* <a
            id="navbarbutton"
            href="/projects"
            className="text-lg font-semibold leading-6 text-gray-900 dark:text-white mr-12"
          >
            Projects
          </a> */}
              <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                <div className="p-4">
                  {products.map((item) => (
                    <div
                      key={item.name}
                      className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6"
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.backgroundColor =
                          item.backgroundColor)
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.backgroundColor = "transparent")
                      }
                    >
                      <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                        <item.icon
                          className="h-6 w-6 text-gray-600 group-hover:text-indigo-600 dark:text-white"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="flex-auto">
                        <a
                          href={item.href}
                          className="block font-semibold text-gray-900"
                        >
                          {item.name}
                          <span className="absolute inset-0" />
                        </a>
                        <p className="mt-1 text-gray-600">{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </Popover.Panel>
              </div>
            {/* </Transition> */}


          </Popover>
     
          <a
            id="navbarbutton"
            href="/research"
            className="text-lg font-semibold leading-6 text-gray-900"
          >
            Research
          </a>
          <a
            id="navbarbutton"
            href="/about"
            className="text-lg font-semibold leading-6 text-gray-900"
          >
            About
          </a>
          <a
            id="navbarbutton"
            href="/contact"
            className="text-lg font-semibold leading-6 text-gray-900"
          >
            Contact
          </a>
        </Popover.Group>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
        {/* <DarkModeToggle onDarkModeToggle={handleDarkModeToggle} /> */}
        <DarkModeToggle isDarkMode={isDarkMode} onDarkModeToggle={toggleDarkMode} />
          <SearchBar />
        </div>
      </nav>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img
                className="h-8 w-auto"
                src={logo}
                alt=""
              />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <Disclosure as="div" className="-mx-3">
                  {/* ... (previous Disclosure content) */}
                </Disclosure>
                <SearchBar />

                <a
                  href="/projects"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-blue-50"
                >
                  Projects
                </a>
                {/* <a
                  href="/taxonomy"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-blue-50"
                >
                  Taxonomy
                </a> */}
                <Popover className="relative">
            <Popover.Button
              id="navbarbuttonmobile"
              style={{ fontSize: '1rem' }}
              className="flex items-center gap-x-1 text-lg font-semibold leading-6 text-gray-900 dark:text-white"
            >
              Taxonomy
              <ChevronDownIcon
                className="h-5 w-5 flex-none text-gray-400 dark:text-white"
                aria-hidden="true"
              />
            </Popover.Button>

            {/* <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            > */}
            <div
  className="transition ease-out duration-200"
  style={{
    transitionProperty: "opacity, transform",
    transitionTimingFunction: "ease-out",
    transitionDuration: "200ms",
  }}
>
              <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                <div className="p-4">
                  {products.map((item) => (
                    <div
                      key={item.name}
                      className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6"
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.backgroundColor =
                          item.backgroundColor)
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.backgroundColor = "transparent")
                      }
                    >
                      <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                        <item.icon
                          className="h-6 w-6 text-gray-600 group-hover:text-indigo-600 dark:text-white"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="flex-auto">
                        <a
                          href={item.href}
                          className="block font-semibold text-gray-900"
                        >
                          {item.name}
                          <span className="absolute inset-0" />
                        </a>
                        <p className="mt-1 text-gray-600">{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </Popover.Panel></div>
            {/* </Transition> */}
          </Popover>
                <a
                  href="/research"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Research
                </a>
                <a
                  href="/about"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  About
                </a>
                <a
                  href="/contact"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Contact
                </a>
              </div>
              <div className="py-6">
                <button
                  type="button"
                  data-collapse-toggle="navbar-search"
                  aria-controls="navbar-search"
                  aria-expanded="false"
                  class="md:hidden text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5 me-1"
                ></button>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );

// return (
//   <header>
//     <nav className="mx-auto flex max-w-100% items-center justify-between p-6 lg:px-8 dark:text-white" aria-label="Global">
//       <div className="flex lg:flex-1">
//         <a href="/" className="-m-4 p-3">
//           <span className="sr-only">Your Company</span>
//           <picture>
//             <source srcSet={logodark} media="(prefers-color-scheme: dark)" />
//             <img src={logo} alt="Your Company Logo" style={{ width: "50%", height: "100%" }} className="navbarlogo" />
//           </picture>
//         </a>
//       </div>
//       <div className="justify-between hidden lg:flex">
//         <a id="navbarbutton" href="/projects" className="text-lg font-semibold leading-6 text-gray-900 dark:text-white mr-12">Projects</a>
//       </div>
//       <div className="flex lg:hidden">
//         <button type="button" className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700" onClick={() => setMobileMenuOpen(true)}>
//           <span className="sr-only">Open main menu</span>
//           <Bars3Icon className="h-6 w-6" aria-hidden="true" />
//         </button>
//       </div>

//       <Popover.Group className="z-9999 hidden lg:flex lg:gap-x-12">
//         <Popover className="relative">
//           <Popover.Button id="navbarbutton" className="flex items-center gap-x-1 text-lg font-semibold leading-6 text-gray-900 dark:text-white">Taxonomy
//             <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400 dark:text-white" aria-hidden="true" />
//           </Popover.Button>

          // <Transition as={Fragment} enter="transition ease-out duration-200" enterFrom="opacity-0 translate-y-1" enterTo="opacity-100 translate-y-0" leave="transition ease-in duration-150" leaveFrom="opacity-100 translate-y-0" leaveTo="opacity-0 translate-y-1">
          //   <div>
          //     <Popover.Panel className="z-9999 absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
          //       <div className="p-4">
          //         {products.map((item) => (
          //           <div key={item.name} className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6" onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = item.backgroundColor)} onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "transparent")}>
          //             <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
          //               <item.icon className="h-6 w-6 text-gray-600 group-hover:text-indigo-600 dark:text-white" aria-hidden="true" />
          //             </div>
          //             <div className="flex-auto">
          //               <a href={item.href} className="block font-semibold text-gray-900">{item.name}<span className="absolute inset-0" /></a>
          //               <p className="mt-1 text-gray-600">{item.description}</p>
          //             </div>
          //           </div>
          //         ))}
          //       </div>
          //     </Popover.Panel>
          //   </div>
          // </Transition>
//         </Popover>
//         <a id="navbarbutton" href="/research" className="text-lg font-semibold leading-6 text-gray-900">Research</a>
//         <a id="navbarbutton" href="/about" className="text-lg font-semibold leading-6 text-gray-900">About</a>
//         <a id="navbarbutton" href="/contact" className="text-lg font-semibold leading-6 text-gray-900">Contact</a>
//       </Popover.Group>
//       <div className="hidden lg:flex lg:flex-1 lg:justify-end">
//         <DarkModeToggle />
//         <SearchBar />
//       </div>
//     </nav>
//     <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
//       <div className="fixed inset-0 z-10" />
//       <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
//         <div className="flex items-center justify-between">
//           <a href="#" className="-m-1.5 p-1.5">
//             <span className="sr-only">Your Company</span>
//             <img className="h-8 w-auto" src={logo} alt="" />
//           </a>
//           <button type="button" className="-m-2.5 rounded-md p-2.5 text-gray-700" onClick={() => setMobileMenuOpen(false)}>
//             <span className="sr-only">Close menu</span>
//             <XMarkIcon className="h-6 w-6" aria-hidden="true" />
//           </button>
//         </div>
//         <div className="mt-6 flow-root">
//           <div className="-my-6 divide-y divide-gray-500/10">
//             <div className="space-y-2 py-6">
//               <Disclosure as="div" className="-mx-3">
//                 {/* ... (previous Disclosure content) */}
//               </Disclosure>
//               <a href="/projects" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-blue-50">Projects</a>
//               {/* <a href="/taxonomy" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-blue-50">Taxonomy</a> */}
//               <Popover className="relative">
//                 <Popover.Button id="navbarbutton" className="flex items-center gap-x-1 text-lg font-semibold leading-6 text-gray-900 dark:text-white">Taxonomy
//                   <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400 dark:text-white" aria-hidden="true" />
//                 </Popover.Button>

//                 <Transition as={Fragment} enter="transition ease-out duration-200" enterFrom="opacity-0 translate-y-1" enterTo="opacity-100 translate-y-0" leave="transition ease-in duration-150" leaveFrom="opacity-100 translate-y-0" leaveTo="opacity-0 translate-y-1">
//                   <div>
//                     <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
//                       <div className="p-4">
//                         {products.map((item) => (
//                           <div key={item.name} className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6" onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = item.backgroundColor)} onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "transparent")}>
//                             <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
//                               <item.icon className="h-6 w-6 text-gray-600 group-hover:text-indigo-600 dark:text-white" aria-hidden="true" />
//                             </div>
//                             <div className="flex-auto">
//                               <a href={item.href} className="block font-semibold text-gray-900">{item.name}<span className="absolute inset-0" /></a>
//                               <p className="mt-1 text-gray-600">{item.description}</p>
//                             </div>
//                           </div>
//                         ))}
//                       </div>
//                     </Popover.Panel>
//                   </div>
//                 </Transition>
//               </Popover>
//               <a href="/about" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">About</a>
//               <a href="/contact" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Contact</a>
//             </div>
//             <div className="py-6">
//               <button type="button" data-collapse-toggle="navbar-search" aria-controls="navbar-search" aria-expanded="false" class="md:hidden text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5 me-1"></button>
//             </div>
//           </div>
//         </div>
//       </Dialog.Panel>
//     </Dialog>
//   </header>
// );
}
