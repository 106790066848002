

import React from 'react';
import '../Stylesheets/ContactPage.css'; 
import contact from '../Assets/contact.png'; 

const ContactPage = () => {
  return (
    <div className="contact-page">
      <h1 id='contact-title'>Do you have cooperation or partnership Ideas?</h1>
      <div className="contact-container">
        <div className="contact-left-section">
          {/* Container 1 */}
          <div className="contact-container">
            <h2 id='left-side-title'>Would you like your project to be added to the database or did you spot one we missed?</h2>
          </div>
          {/* Container 2 */}
          <div className="contact-container">
            <img id='contactpicture' src={contact} alt="contact" />
          </div>
          {/* Container 3 */}
          {/* <div className="contact-container">
            <h2>Email: <span>CONTACT@RWA.WORLD</span>   </h2>
            <h2>DM on Twitter</h2>
          </div> */}
          <div className="contact-container">
  {/* <h2 id='left-side-title'>Would you like your project to be added to the database or did you spot one we missed?</h2> */}
  <div className="contact-info">
    <span>Email: <span id="email-span"></span></span>
    <h2>Contact@RWA.World</h2>
  </div>
</div>
          
        </div>
        <div className="right-section">
          <form className="contact-form">
            <input type="text" placeholder="Name" />
            <input type="text" placeholder="Company Name" />
            <input type="email" placeholder="Email" />
            <textarea placeholder="Message"></textarea>
            <button id='submitbutton' type="submit">Send It</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
